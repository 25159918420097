import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://node.mumber.com/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        toast.error(data.error);
      } else {
        toast.promise(
          new Promise((resolve) => {
            setEmail('');
            resolve();
          }),
          {
            pending: 'Sending password reset link...',
            success: 'Password reset link sent to your email',
            error: 'Failed to send reset link',
          }
        );
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to send reset link');
    }
  };

  return (
    <div>

<div className='d-flex justify-content-center align-items-center bg-primary vh-100'>
<ToastContainer position="top-right" />
      <div className='bg-white p-3 rounded  text-center'>
      <h3>
                      <i className="fa fa-lock fa-2x"></i>
                    </h3>
                    <h2 className="text-center" style={{ color: 'black' }}>
                      Forgot Password?
                    </h2>
                    <p style={{ color: 'black' }}>You can reset your password here.</p>
        <br />

        <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                          <div className="input-group">
                            
                            <input
                              id="email"
                              name="email"
                              placeholder="email address"
                              style={{
                                background: '#fff',
                                border: '1px solid #ced4da',
                                borderRadius: '4px',
                                padding: '6px 12px',
                                width: '100%',
                                boxSizing: 'border-box',
                              }}
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          {/* {error && <div className="alert alert-danger">{error}</div>} */}
                        </div>
                        <div className="form-group ">
                          <input
                            name="recover-submit"
                            className="btn btn-lg btn-primary btn-block"
                            value="Reset Password"
                            type="submit"
                          />
                        </div>
                        <input type="hidden" className="hide" name="token" id="token" value="" />
                      </form>
                      <Link to='/login' style={{textDecoration:"none"}}>LOGIN</Link>
       
        
      </div>
    </div>
     </div>


  );
};

export default Forgot;