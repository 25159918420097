import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Preloader from '../../Preloader';

function Allblogs() {
  
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get("https://node.mumber.com/api/admin/allblogs");

        // Once the data is fetched, set isLoading to false
        setIsLoading(false);

        // Process the data further if needed
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const [data, setData] = useState([]);
  
  const columns = [
    {
        name: 'ID',
        selector: (row, index) => index + 1,
        sortable: true,
    },
    {
      name: 'Title',
      selector: row => row.Title,
      sortable: true,
  },
    {
        name: 'Description',
        selector: row => row.ShortDescription,
        sortable: true,
    },
  //   {
  //     name: 'Categories',
  //     selector: row => row.Categories,
  //     sortable: true,
  // },
  {
    name: 'images',
    selector: row => row.images,
    sortable: true,
},
{
  name: 'Publish date',
  selector: row => new Date(row.publish_date).toLocaleDateString(),
  sortable: true,
},
  {
 
    button: true,
    cell: (row) => <Link to={`/updateBlog/${row.Id}`} >Edit</Link>,
  },
  {
    
    button: true,
    cell: (row) => <i onClick={() => deleteBlog(row.Id)} class="fa-solid fa-trash-can"></i>,
  },
];
const [filterText, setFilterText] = useState('');

const handleFilter = (e) => {
  const value = e.target.value;
  setFilterText(value);
};

const filteredData = data.filter((item) =>
  item.Title.toLowerCase().includes(filterText.toLowerCase()) ||
  item.ShortDescription.toLowerCase().includes(filterText.toLowerCase())||
  // item.Categories.toLowerCase().includes(filterText.toLowerCase()) ||
  item.Description.toLowerCase().includes(filterText.toLowerCase()) 
);


const [selectedRows, setSelectedRows] = useState([]);

const handleRowSelected = (rows) => {
  setSelectedRows(rows.selectedRows);
};
    const loadData = async () => {
      try {
        const response = await axios.get("https://node.mumber.com/api/admin/allblogs");
        setData(response.data.data);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch data");
      }
    };
    
    useEffect(() => {
      loadData();
    }, []);
    
  
   
    const deleteBlog = async (id) => {
      try {
        if (window.confirm('Are you sure you want to delete this blog?')) {
          await axios.delete(`https://node.mumber.com/api/removeblog/${id}`);
          toast.success('Blog deleted successfully');
          loadData();
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to delete Blog');
      }
    };
    return (
      <>
      {isLoading ? (
        <div className="content-wrapper">
        <Preloader />
        </div>
      ) : (
        <div className="content-wrapper">
          <p className='all-pro'>Blog</p>
    
          <div className='cust-list-box'>
            <div className='container-fluid'>
              <div className='row'></div>
            </div>
            <input type="text" style={{width:"50%",
              height:"60px",
              borderRadius:"50px",
              paddingLeft:'20px',
              paddingRight:'20px',
              }}className='mb-4 mt-4 mx-3' onChange={handleFilter} placeholder="
              Search by Title and Description
              " />
            <DataTable
              title="Blog"
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={selectedRows.length > 0}
            />
          </div>
        </div>
          )}
      </>
    );
}

export default Allblogs
