import React, { useState, useEffect }  from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './products/add-products.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Gateway_setting = () => {

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    client_id: '',
    client_secret: '',
    company_id: '',
  });

  const loadData = async () => {
    try {
    const response = await axios.get("https://node.mumber.com/api/get_gateway_settings");
      setData(response.data.data[0]);

      setFormData({
        client_id: response.data.data[0].client_id,
        client_secret: response.data.data[0].client_secret,
        company_id: response.data.data[0].company_id,
    });

      // console.log(response.data.data[0].client_id);
      // console.log(data.client_id);
    } catch (error) {
      console.log(error);

    }
  };

  useEffect(() => {
    loadData();
  }, []);

 
    // const [selectedPlanName, setSelectedPlanName] = useState('');
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const loadData = async () => {
//       try {
//         const response = await axios.get('https://node.mumber.com/api/getCategories');
//         setData(response.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     loadData();
//   }, []);

  

  const navigate = useNavigate();

  const { client_id, client_secret, company_id } = formData;

  const handleChange = (e) => {
 
     
      setFormData({ ...formData, [e.target.name]: e.target.value });
    
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    try {
      const res = await axios.post('https://node.mumber.com/api/addgatewaysettings', formData);
      console.log(res.data);
    } catch (err) {
      console.log(err.response.data);
    }
    toast.success('Data inserted successfully!');
    setFormData({
        client_id: data.client_id,
        client_secret: data.client_secret,
        company_id: data.company_id,
    });
    // navigate('/allproducts');
    console.log(formData);
  };


  
    return (
        <div>
      <div className="content-wrapper">

        <p className='all-pro'>Gateway Settings</p>
        
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className='add-pro-box1 mt-5 mb-5'>
            <h2>API Information</h2>
           
            <div>
              <label className='mt-4'>Client ID</label><br></br>
              <input className='add-pro-input' 
              type="text" defaultValue={data.client_id} name="client_id"  onChange={handleChange} 
             
              required />
            </div>
            <label className="mt-3">Client Secret</label><br></br>
            <input className='add-pro-input' type="text" name="client_secret" defaultValue={data.client_secret}  onChange={handleChange} required />


            <label className="mt-3">Company ID</label><br></br>
            <input className='add-pro-input' type="text" name="company_id" defaultValue={data.company_id} onChange={handleChange} required />


          


          





            

          </div>




          
          <div className='pro-last'>

            <button type="submit" className='pro-but mt-5 mb-5'>Save</button>
          </div>

        </form>
      </div>
    </div>
    )
}

export default Gateway_setting;