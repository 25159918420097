import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Auth_nav from "./nav_auth_buttons";
export default class Header extends Component {


  
    render() {
        return (
           <div>
  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    {/* Left navbar links */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
      <NavLink to="/" className="nav-link">Home</NavLink>
      </li>
      {/* <li className="nav-item d-none d-sm-inline-block">
        <NavLink to="contact" className="nav-link">Contact</NavLink>
      </li> */}
    </ul>
    <Auth_nav></Auth_nav>

  </nav>
</div>

        )
    }
}