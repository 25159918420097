import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Preloader from '../../Preloader';

import axios from 'axios';
import DataTable from 'react-data-table-component';

function Notification() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);


  useEffect(() => {
    axios
      .get('https://node.mumber.com/notifications')
      .then((res) => {
        setNotifications(res.data.data);
        setIsLoading(false);

      })
      .catch((err) => console.log(err));
  }, []);

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Message',
      selector: 'message',
      sortable: true,
    },
    {
      name: 'Notify To',
      selector: 'notify_to',
      sortable: true,
    },
    {
      name: 'User ID',
      selector: 'user_id',
      sortable: true,
    },
  ];

  return (
    <>
    {isLoading ? (
      <div className="content-wrapper">
      <Preloader />
      </div>
    ) : (
      <div className="content-wrapper">
        <p className='all-pro'>Notification</p>
  
        <div className='cust-list-box'>
          <div className='container-fluid'>
            <div className='row'></div>
          </div>
          {/* <input type="text" onChange={handleFilter} placeholder="Search" /> */}
          <DataTable columns={columns} data={notifications} />
        </div>
      </div>
        )}
    </>
  );
}

export default Notification;
