import React from 'react';
// import React, { useEffect } from 'react';
import './order-details.css';
// import tinymce from 'tinymce/tinymce';


const Orderdetails = () => {

    

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://cdn.tiny.cloud/1/vcg1lfp2mqa9fankg6h9k10zfdbrmpa2bj9bjrpr9as4hoqk/tinymce/6/tinymce.min.js';
    //     script.referrerPolicy = 'origin';
    //     script.async = true;
    //     script.onload = () => {
    //       window.tinymce.init({
    //         selector: 'textarea',
    //         plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
    //         toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    //         tinycomments_mode: 'embedded',
    //         tinycomments_author: 'Author name',
    //         mergetags_list: [
    //           { value: 'First.Name', title: 'First Name' },
    //           { value: 'Email', title: 'Email' },
    //         ],
    //       });
    //     };
    //     document.body.appendChild(script);
    //   }, []);
   

    return (
        <>
            <div className='content-wrapper'>
                <div className='all-pro'>
                    Order Details
                </div>

                <div className='container-fluid'>


                    <div className='order-detail-tab'>
                        <table class="table">

                            <thead>

                                <tr>

                                    <th scope="col">Transactions</th>
                                    <th scope="col"></th>
                                    <th scope="col">
                                        <a href='#' >Add transaction</a>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>

                                <tr>

                                    <td>payment
                                        <br></br>
                                        via pay pal
                                    </td>
                                    <td>feb-3-1999</td>
                                    <td>$2000</td>

                                </tr>
                                <tr>

                                    <td>payment
                                        <br></br>
                                        from account balance
                                    </td>
                                    <td>feb-3-1999</td>
                                    <td>$700</td>

                                </tr>
                                <tr>

                                    <td>Refund</td>
                                    <td>feb-3-1999</td>
                                    <td>$540</td>

                                </tr>
                            </tbody>
                        </table>


                    </div>





                    <div className='container-fluid '>


                        <div className='order-detail-tab mt-5'>
                            <table class="table">

                                <thead>

                                    <tr>

                                        <th scope="col">Balance</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>

                                        <td>Order Total
                                        </td>
                                        <td></td>
                                        <td>$2000</td>

                                    </tr>
                                    <tr>

                                        <td>Return Total
                                        </td>
                                        <td></td>
                                        <td>$700</td>

                                    </tr>
                                    <tr>

                                        <td>Paid by customer</td>
                                        <td></td>
                                        <td>$540</td>

                                    </tr>

                                    <tr>

                                        <td>Refund</td>
                                        <td></td>
                                        <td>$540</td>

                                    </tr>

                                    <tr>

                                        <td>Balance</td>
                                        <td></td>
                                        <td>$4560</td>

                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>


                    {/* <div className='text-area'>
                    <textarea></textarea>
                    </div>
                     */}
               








  


 















                </div>
            </div>








        </>
    )
}

export default Orderdetails
