import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './all-customers.css';
import DataTable from 'react-data-table-component';
import Preloader from '../../Preloader';

const Upcominginvoices = () => {

  const [gatewaydata, setGatewaydata] = useState([]);

    const loadData11 = async () => {
      try {
      const response = await axios.get("https://node.mumber.com/api/get_gateway_settings");
      setGatewaydata(response.data.data[0]);
  
        
  
        // console.log(response.data.data[0].client_id);
        
      } catch (error) {
        console.log(error);
  
      }
    };
  
    useEffect(() => {
      loadData11();
    }, []);


  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get("https://node.mumber.com/api/getcustomersinvoices");

        // Once the data is fetched, set isLoading to false
        setIsLoading(false);

        // Process the data further if needed
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  // function convertToUSDateFormat(dateString) {
  //   // Parse the input date string
  //   const date = new Date(dateString);
  
  //   // Convert the date to the US format (mm/dd/yyyy)
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const day = date.getDate().toString().padStart(2, '0');
  //   const year = date.getFullYear();
  
  //   return `${month}/${day}/${year}`;
  // }

  function convertToUSDateFormat(dateString) {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(date);
    return formattedDate;
  }

  const [data, setData] = useState([]);
  
  const columns = [
    {
        name: 'ID',
        selector: (row, index) => index + 1,
        sortable: true,
    },
    {
      name: 'First Name',
      selector: row => row.first_name,
      sortable: true,
  },
    {
        name: 'Last Name',
        selector: row => row.last_name,
        sortable: true,
    },{
      name: 'Email',
      selector: row => row.email_address,
      sortable: true,
  },
  {
    name: 'Invoice Due on',
    selector: row => convertToUSDateFormat(row.due_on),
       
    sortable: true,
   },
   {
    name: 'Invoice Amount',
    selector: row => '$'+row.amount,
    sortable: true,
   },
 
  {
    
    button: true,
    cell: (row) => {
      if(row.paid==0){
        return <button style={{width: "80px"}} onClick={() => handleCharge(row)} className='btn btn-sm btn-primary'>Charge</button>
      }else{
        return <button style={{width: "80px"}}  className='btn btn-sm btn-success'>Charged</button>
      }
     
    },
  },
  {
    
    button: true,
    cell: (row) => {
   
        return <Link to={'/vieworder/'+row.order_id}>View Order</Link>
      
      
    },
  }
];
const [filterText, setFilterText] = useState('');

  const handleFilter = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  // const filteredData = data.filter((item) =>
  //   item.first_name.toLowerCase().includes(filterText.toLowerCase())
  //   || item.last_name.toLowerCase().includes(filterText.toLowerCase())
  // );

  const filteredData = data.filter((item) =>
  (item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase()))
  || (item.last_name && item.last_name.toLowerCase().includes(filterText.toLowerCase()))
);


const [selectedRows, setSelectedRows] = useState([]);

const handleRowSelected = (rows) => {
  setSelectedRows(rows.selectedRows);
};
    const loadData = async () => {
      try {
        const response = await axios.get("https://node.mumber.com/api/getcustomersinvoices");
        setData(response.data.data);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch data");
      }
    };
    
    useEffect(() => {
      loadData();
    }, []);


    const handleCharge = async (customer) => {
        try {

          setLoading(true);
          if (window.confirm("Are you sure you want to recieve payment from this?")) {


            const auth_token = await fetchToken();

         

            const paymentResponse = await makePayment(auth_token, customer);

            const create_payment = await createPayment(auth_token,customer);
            console.log(create_payment);


            const generate_next_invoice = await generateNextInvoice(auth_token,customer);

            const update_next_invoice= await updateCustomerNextInvoice(generate_next_invoice, customer);

            
            // await axios.delete(`https://node.mumber.com/api/removecustomer/${id}`);
            // toast.success("categorie deleted successfully");
            loadData();
            setLoading(false);
            // toast.success("Invoice Charged Successfuly");
            toast.success('Invoice Charged Successfuly', { position: toast.POSITION.TOP_CENTER });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          toast.error("Failed to delete categorie");
        }
      // window.location.reload();
  
      };


      const fetchToken = async () => {
        try {
          const response = await axios.get('https://node.mumber.com/api/getToken');
          const auth_token = response.data;
          // console.log('Token:', auth_token);
          return auth_token;
        } catch (error) {
          throw new Error('Failed to fetch token');
        }
      };





  const makePayment = async (auth_token, customer) => {


    const url = 'https://sandbox.api.intuit.com/quickbooks/v4/payments/charges';
    const data = {
      currency: 'USD',
      amount: parseFloat(customer.amount),
      context: {
        mobile: 'false',
        isEcommerce: 'true'
      },
      cardOnFile: customer.card_id
    };

    try {
     const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth_token,
          'request-Id': Date.now()
        }
      });
  
      console.log('Payment response:', response.data);
      return response;

    } catch (error) {
      // alert('Invalid Card details!');
      toast.error('Invalid Card details!', { position: toast.POSITION.TOP_CENTER });

      throw new Error('Failed to make payment');
    }




  };



  const createPayment = async (auth_token, customer) => {


    try {

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
 

    const create_cust_url = 'https://sandbox-quickbooks.api.intuit.com/v3/company/'+(gatewaydata.company_id).trim()+'/payment?minorversion=65';
    const cust_data = {
      "TotalAmt": parseFloat(customer.amount), 
      "CustomerRef": {
        "value": customer.gateway_customer_id
      },
      "Line": [
          {
              "Amount": parseFloat(customer.amount),
                    "LinkedTxn": [
                        {
                            "TxnId": customer.invoice_id,
                            "TxnType": "Invoice"
                        }
                    ]
            
          }
      ]
    };


     

      const response = await axios.post('https://node.mumber.com/create_payment', {
        url: create_cust_url,
        data: cust_data,
        token: auth_token
      });

      return response.data;

    } catch (error) {
      // alert('Invalid Card details!');
      toast.error('Invalid Card details!', { position: toast.POSITION.TOP_CENTER });

      throw new Error('Failed to make payment');
    }


   

  }


  const generateNextInvoice = async (auth_token, customer) => {


    try {

      let formattedDate = "";

      if(customer.plan=="Yearly"){
        const currentDate = new Date(customer.due_on);
        currentDate.setDate(currentDate.getDate() + 365);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        formattedDate = `${year}-${month}-${day}`;
      }else{
        const currentDate = new Date(customer.due_on);
        currentDate.setDate(currentDate.getDate() + 30);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        formattedDate = `${year}-${month}-${day}`;
      }

    const create_cust_url = 'https://sandbox-quickbooks.api.intuit.com/v3/company/'+(gatewaydata.company_id).trim()+'/invoice?minorversion=65';
    const cust_data = {
      "Line": [
        {
          "DetailType": "SalesItemLineDetail", 
          "Amount": parseFloat(customer.amount), 
          "SalesItemLineDetail": {
            "ItemRef": {
              "name": "Number Purchased", 
              "value": "1"
            }
          }
        }
      ], 
      "CustomerRef": {
        "value": customer.gateway_customer_id
      },
      "DueDate": formattedDate
    };


     

      const response = await axios.post('https://node.mumber.com/generate_invoice', {
        url: create_cust_url,
        data: cust_data,
        token: auth_token
      });

      return response.data;

    } catch (error) {
      // alert('Invalid Card details!');
      toast.error('Invalid Card details!', { position: toast.POSITION.TOP_CENTER });

      throw new Error('Failed to make payment');
    }


   

  }


  const updateCustomerNextInvoice = async (generate_next_invoice, customer) => {

    try {

      let formattedDate = "";

      if(customer.plan=="Yearly"){
        const currentDate = new Date(customer.due_on);
        currentDate.setDate(currentDate.getDate() + 365);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        formattedDate = `${year}-${month}-${day}`;
      }else{
        const currentDate = new Date(customer.due_on);
        currentDate.setDate(currentDate.getDate() + 30);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        formattedDate = `${year}-${month}-${day}`;
      }

      // console.log(add_card);

      const up_data = {
        date: formattedDate,
        invoice_id: generate_next_invoice.Invoice.Id,
        amount: customer.amount,
        plan: customer.plan,
        user_id: customer.user_id,
        order_id: customer.order_id,
        current_invoice_id : customer.id
      };
    const resp = await axios.post('https://node.mumber.com/api/update_customer_next_invoice',{
      data: up_data
    });
      // alert("here");
      // console.log(resp);
      return resp.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error('Invalid Card details!', { position: toast.POSITION.TOP_CENTER });

      throw new Error('Failed to make payment');
    }



  }





    return (
      <>
      {isLoading ? (
        <div className="content-wrapper">
        <Preloader />
        </div>
      ) : (
      <div className="content-wrapper">
          <p className='all-pro'>Upcoming Invoices</p>


          <div className='cust-list-box'>
         <div className='container-fluid'>
          <div className='row'>
          {/* <form class="d-flex" role="search">
<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
<button class="btn all-pro-search" type="submit">Search</button>
</form> */}
          </div>
         </div>
         <input type="text" className='mb-4 mt-4 mx-3' onChange={handleFilter} placeholder="Search by Name " />
         <DataTable
title="Customer "
columns={columns}
data={filteredData}
selectableRows
pagination
onSelectedRowsChange={handleRowSelected}
clearSelectedRows={selectedRows.length > 0}
/>

      </div>
      </div>
      )}

      

      {loading && (
        <div id="loader" style={{background: "#fff", position: "fixed", width: "100%", height: "100%", zIndex:"99999", top:"0px"}}>
          <Preloader />
        </div>
      )}

<ToastContainer />
  </>
    )
}

export default Upcominginvoices
