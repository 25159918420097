import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Vieworder = ({ orderId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
    const id = window.location.pathname.split("/").pop();

        const response = await axios.get(`https://node.mumber.com/api/vieworder/${id}`);
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [orderId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!data) {
    return null;
  }

  const { products, customer, orderDetails } = data;
  // const orderDate = new Date(orderDetails.Date);


        
const utcDate = (orderDetails.Date);
          const newDate = utcDate.split("T");
          const newDate1 = newDate[0].split("-");
          const formattedOrderDate = newDate1[1]+"/"+newDate1[2]+"/"+newDate1[0];
        


  function convertToUSDateFormat(dateString) {
    // Parse the input date string
    const date = new Date(dateString);
  
    // Convert the date to the US format (mm/dd/yyyy)
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }

  

  function formatPhoneNumber(phoneNumber) {
    
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      console.log(phoneNumber);
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    
    return phoneNumber;
  }

  // const orderDate = new Date(orderDetails.Date);
  // const formattedOrderDate = convertToUSDateFormat(orderDate);
  const formattedPhoneNumber = formatPhoneNumber(customer.phone);

  return (
    <div className='content-wrapper'>
    <div className='all-pro'>ORDER-DETAIL</div>
    <div className='add-pro-box1 mt-5 mb-5'>
    <h2>Order Information</h2>
      <p>Order ID: {orderDetails.Id}</p>
      <p>Name: {orderDetails.name}</p>
      <p>Date: {formattedOrderDate}</p>
      <p>Status:<span style={{color:'green'}}>{orderDetails.Status}</span> </p>
      <h3>Total: ${parseFloat(orderDetails.Total).toFixed(2)}</h3>

      <h2>Customer Information</h2>
      {/* <p>Customer ID: {customer.id}</p> */}
      <p>Name: {customer.first_name} {customer.last_name}</p>
      <p>Company: {customer.company_name}</p>
      <p>Address: {customer.street_address}, {customer.town}, {customer.state}, {customer.ZIP_Code}</p>
      <p>Phone: {formatPhoneNumber(customer.phone)}</p>
      <p>Email: {customer.email_address}</p>

      <h2>Product Information</h2>
      {products.map((product) => (
        <div key={product.id}>
          <h3>Item #</h3>
          <p>Phone No: {formatPhoneNumber(product.phone_no)}</p>
          <p>SKU: {product.SKU}</p>
          {/* <p>Categories: {product.Categories}</p> */}
          {/* <p>State: {product.State}</p>
          <p>City: {product.City}</p> */}
          <p>Area Code: {product.area_code}</p>
          <p>Description: {product.Description}</p>
          <p>Monthly Price: ${parseFloat(product.monthly).toFixed(2)}</p>
          <p>Yearly Price: ${parseFloat(product.yearly).toFixed(2)}</p>
          {/* <p>Description: {product.Description}</p> */}
        </div>
      ))}
    </div>




    </div>
  );
};

export default Vieworder