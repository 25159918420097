import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './add-products.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Addproducts = () => {
  const [selectedPlanName, setSelectedPlanName] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get('https://node.mumber.com/api/getCategories');
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    loadData();
  }, []);

  const [formData, setFormData] = useState({
    phone_no: '',
    SKU: '',
    Categories: '',
    // State: '',
    // City: '',
    area_code: '',
    monthly: '',
    yearly: '',
    Description: '',
    Nickname: '',
  });

  const navigate = useNavigate();

  const { phone_no, SKU, Nickname, area_code, monthly, yearly, Description } = formData;

  const handleChange = (e) => {
    if (e.target.name === 'plan') {
      const selectedPlan = e.target.options[e.target.selectedIndex];
      const planValue = selectedPlan.value;
      const planName = selectedPlan.text;
      setFormData({ ...formData, plan: planValue, planName: planName });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://node.mumber.com/api/addproduct', formData);
      console.log(res.data);
    } catch (err) {
      console.log(err.response.data);
    }
    toast.success('Data inserted successfully!');
    setFormData({
      phone_no: '',
      SKU: '',
      Categories: '',
      State: '',
      City: '',
      area_code: '',
      monthly: '',
      yearly: '',
      Description: '',
      Nickname: '',
    });
    // navigate('/allproducts');
    console.log(formData);
  };

  function phone(event) {
    const input = event.target;
    const keyCode = event.keyCode || event.which;
    const key = event.key;
  
    if (!(keyCode === 8 || (keyCode >= 48 && keyCode <= 57))) {
      event.preventDefault();
      return;
    }
  
    if (input.value.length === 3 && keyCode !== 8) {
      input.value = input.value.slice(0, 3) + '-' + input.value.slice(3);
    } else if (input.value.length === 7 && keyCode !== 8) {
      input.value = input.value.slice(0, 7) + '-' + input.value.slice(7);
    }
  }
  
  return (
    <div>
      <div className="content-wrapper">

        <p className='all-pro'>ADD-PRODUCT</p>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className='add-pro-box1 mt-5 mb-5'>
            <h2>Basic Information</h2>
            <div>
              <label className='mt-4'>PhoneNumber</label><br></br>
              <input className='add-pro-input' 
              type="text" name="phone_no" value={phone_no} onChange={handleChange} 
              onKeyDown={phone}
                  maxlength="12"
              required />
            </div>
            <label className="mt-3">Monthly Fee</label><br></br>
            <input className='add-pro-input' type="text" name="monthly" value={monthly} onChange={handleChange} required />


            <label className="mt-3">Yearly Fee</label><br></br>
            <input className='add-pro-input' type="text" name="yearly" value={yearly} onChange={handleChange} required />


            <label className="mt-3">Keywords/Description</label><br></br>
            <textarea className='add-pro-input'  name="Description"  value={Description} onChange={handleChange} required>{Description}</textarea>



            {/* <label className="mt-1" htmlFor="sel1">
              Plan
            </label>
            <select
              name="plan"
              value={plan}
              onChange={handleChange}
              required
              className="form-control add-pro-input"
              id="sel1"
            >
              <option></option>
              <option value="656">Monthly</option>
              <option value="7624">Yearly</option>
            </select> */}






            <label className="mt-3">SKU</label><br></br>
            <input className='add-pro-input' type="text" name="SKU" value={SKU} onChange={handleChange} required />

            <label className="mt-3">Nickname</label><br></br>
            <input className='add-pro-input' type="text" name="Nickname" value={Nickname} onChange={handleChange} required />
           {/* <label className="mt-3">State</label><br></br>
            <input className='add-pro-input' type="text" name="State" value={State} onChange={handleChange}  />

            <label className="mt-3">City</label><br></br>
            <input className='add-pro-input' type="text" name="City" value={City} onChange={handleChange}  /> */}




            <label className="mt-3">Area Code</label><br></br>
            <input className='add-pro-input' type="text" name="area_code" value={area_code} onChange={handleChange} required />


            

          </div>




          {/* <div className='add-pro-box1 mt-5 '>
          <h3>Plan</h3>


          <label for="sel1">Plan</label>
          <select  name="plan" value={plan} onChange={handleChange} required 
          class="form-control add-pro-input" id="sel1">
            <option></option>
            <option>Monthly</option>
            <option>Yearly</option>
          </select>
        </div> */}

          <div className='pro-last'>

            <button type="submit" className='pro-but mt-5 mb-5'>Save</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Addproducts;
