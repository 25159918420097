import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './all-customers.css';
import DataTable from 'react-data-table-component';
import Preloader from '../../Preloader';

const Allcustomers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterText, setFilterText] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);


  const [searchText, setSearchText] = useState('');

  const fetchData = async (page, search = '') => {
    try {
      const response = await axios.get(
        `https://node.mumber.com/api/getcustomers?page=${page}&perPage=${perPage}&search=${search}`
      );

      setIsLoading(false);
      setTotalPages(response.data.total);
      setData(response.data.data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, searchText);
  }, [currentPage, searchText, perPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'First Name',
      selector: (row) => row.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email_address,
      sortable: true,
    },
    {
      button: true,
      cell: (row) => <Link to={`/updatecustomer/${row.id}`}>Edit</Link>,
    },
    {
      button: true,
      cell: (row) => (
        <i onClick={() => deletecustomer(row.id)} className="fa-solid fa-trash-can"></i>
      ),
    },
  ];


 

  const deletecustomer = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this customer?")) {
        await axios.delete(`https://node.mumber.com/api/removecustomer/${id}`);
        toast.success("Customer deleted successfully");
        fetchData(currentPage);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete customer");
    }
  };


  const handleFilter = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const filteredData = data.filter((item) =>
    item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
    item.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
    item.email_address.toLowerCase().includes(searchText.toLowerCase())
  );
console.log(filteredData);


  const handleRowSelected = (rows) => {
    setSelectedRows(rows.selectedRows);
  };

  return (
    <>
      {isLoading ? (
        <div className="content-wrapper">
          <Preloader />
        </div>
      ) : (
        <div className="content-wrapper">
          <p className="all-pro">Customer</p>

          <div className="cust-list-box">
            <div className="container-fluid">
              <div className="row">
                {/* <form class="d-flex" role="search">
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button class="btn all-pro-search" type="submit">
                    Search
                  </button>
                </form> */}
              </div>
            </div>
            <input
              type="text"
              style={{
                width: "50%",
                height: "60px",
                borderRadius: "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              className="mb-4 mt-4 mx-3"
              onChange={handleFilter}
              placeholder="Search by Name "
            />
            <DataTable
        title="Customers"
        columns={columns}
        data={filteredData}
        selectableRows
        pagination
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={selectedRows.length > 0}
        paginationServer
        paginationTotalRows={totalPages} // Adjust the total rows count based on perPage
        paginationPerPage={perPage} // Number of items per page
        onChangePage={handlePageChange} // Callback when changing page
        onChangeRowsPerPage={(newPerPage) => {
          if (newPerPage === "all") {
            setPerPage(data.length); // Show all rows if "All" is selected
          } else {
            setPerPage(newPerPage); // Set the selected rows per page value
            setCurrentPage(1); // Reset to the first page when changing rows per page
          }
        }}
        paginationRowsPerPageOptions={[10, 25, 50]} // Available options for rows per page
      />
          </div>
        </div>
      )}
    </>
  );
};

export default Allcustomers;
