import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const Editfaq = () => {
  const [data, setData] = useState([]);
  const [faq, setfaq] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://node.mumber.com/api/getCategories");
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchfaq = async () => {
      try {
        const id = window.location.pathname.split("/").pop();
        const response = await axios.get(`https://node.mumber.com/api/faq/${id}`);
        setfaq(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchfaq();
  }, []);

  const updatefaq = () => {
    const Title = document.getElementById("Title").value;
    const Description = faq.Description;
    const ShortDescription = faq.ShortDescription;

    const formData = new FormData();
    formData.append("Title", Title);
    formData.append("Description", Description);
    formData.append("ShortDescription", ShortDescription);
    formData.append("image", imageFile);

    const id = window.location.pathname.split("/").pop();
    axios.put(`https://node.mumber.com/api/updatefaq/${id}`, formData)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          alert("faq updated successfully");
          navigate("/allfaqs");
        } else {
          alert("faq update failed");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleChangeShortDescription = (e) => {
    const value = e.target.value;
    setfaq({ ...faq, ShortDescription: value });
  };

  const handleChangeDescription = (value) => {
    setfaq({ ...faq, Description: value });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  return (
    <>
      <div className='content-wrapper'>
        <div className='all-pro'>UPDATE faq</div>
        <div className='add-pro-box1 mt-5 mb-5'>
          <h2>Basic Information</h2>
          <div>
            <label className='mt-4' htmlFor="Title">Title</label><br />
            <textarea className='add-pro-input'
              type="text"
              id="Title"
              name="Title"
              defaultValue={faq.Title}
              required />

            <label className='mt-4' htmlFor="ShortDescription">ShortDescription</label><br />
            <textarea className='add-pro-input'
              type="text"
              id="ShortDescription"
              name="ShortDescription"
              defaultValue={faq.ShortDescription}
              onChange={handleChangeShortDescription}
              required />

          
            <label className='mt-4' htmlFor="Description">Description</label><br />
            <ReactQuill
              id="Description"
              name="Description"
              modules={modules}
              value={faq.Description}
              onChange={handleChangeDescription}
              required />

           

            <label className='mt-4' htmlFor="image">Image</label><br />
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
            <br />
            <img className="mt-4" src={`https://node.mumber.com/uploads/${faq.image}`} alt="faq Image" />
          </div>
        </div>

        <div className='pro-last'>
          <button type="button" onClick={updatefaq} className='pro-but mt-5 mb-5'>Update</button>
        </div>
      </div>
    </>
  );
};

export default Editfaq;
