import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Preloader from '../../Preloader';
import DataTable from 'react-data-table-component';

function Allproducts() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://node.mumber.com/api/allproducts?page=${currentPage}&perPage=${perPage}`
        );
        setIsLoading(false);
        setTotalCount(response.data.total);
        setData(response.data.data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        toast.error('Failed to fetch data');
      }
    };
    fetchData();
  }, [currentPage, perPage]);

  useEffect(() => {
    loadData();
  }, [filterText, currentPage, perPage]);

  const loadData = async () => {
    try {
      const response = await axios.get(
        `https://node.mumber.com/api/allproducts?page=${currentPage}&perPage=${perPage}&search=${filterText}`
      );
      setIsLoading(false);
      setTotalCount(response.data.total);
      setData(response.data.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error('Failed to fetch data');
    }
  };

  const handleRowSelected = (rows) => {
    setSelectedRows(rows.selectedRows);
  };

  // const handleFilter = async (e) => {
  //   const value = e.target.value;
  //   setFilterText(value);

  //   // Fetch filtered data from the server based on the search text
  //   try {
  //     const response = await axios.get(
  //       `https://node.mumber.com/api/allproducts?page=${currentPage}&perPage=${perPage}&search=${value}`
  //     );
  //     setIsLoading(false);
  //     setTotalCount(response.data.total);
  //     setData(response.data.data);
  //   } catch (error) {
  //     console.error(error);
  //     setIsLoading(false);
  //     toast.error('Failed to fetch data');
  //   }
  // };


  const handleFilter = async (e) => {
    const value = e.target.value;
    setFilterText(value);
  }

  const filteredData = data.filter(
    (item) =>
    (item.phone_no?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.area_code?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.Nickname?.toLowerCase().includes(filterText.toLowerCase()))
  );

  console.log(filteredData);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      console.log(phoneNumber);
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  };

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'PhoneNumber',
      selector: (row) => formatPhoneNumber(row.phone_no),
      sortable: true,
    },
    {
      name: 'monthly',
      selector: (row) => {
        const roundedmonthly = Math.ceil(row.monthly * 100) / 100;
        return ' $ ' + roundedmonthly.toFixed(2);
      },
      sortable: true,
    },
    {
      name: 'yearly',
      selector: (row) => {
        const roundedYearly = Math.ceil(row.yearly * 100) / 100;
        return ' $ ' + roundedYearly.toFixed(2);
      },
      sortable: true,
    },
    {
      name: 'SKU',
      selector: (row) => formatPhoneNumber(row.SKU),
      sortable: true,
    },
    {
      name: 'Area Code',
      selector: (row) => row.area_code,
      sortable: true,
    },
    {
      name: 'NickName',
      selector: (row) => row.Nickname,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.Description,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (row.Is_sold === 1 ? 'Sold' : 'Available'),
      sortable: true,
    },
    {
      button: true,
      cell: (row) => <Link to={`/update/${row.id}`}>Edit</Link>,
    },
    {
      button: true,
      cell: (row) => (
        <i onClick={() => deleteProduct(row.id)} className="fa-solid fa-trash-can"></i>
      ),
    },
  ];

  const deleteProduct = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete this product?')) {
        await axios.delete(`https://node.mumber.com/api/removeProduct/${id}`);
        toast.success('Product deleted successfully');
        loadData();
      }
    } catch (error) {
      console.log(error);
      toast.error('Failed to delete product');
    }
  };

  const getCurrentPageData = () => {
    // Calculate the starting index of the current page
    const startIndex = (currentPage - 1) * perPage;
    // Return a slice of the original data based on the current page and rows per page settings
    return data.slice(startIndex, startIndex + perPage);
  };
  const dataToDisplay = filterText ? filteredData : getCurrentPageData();


  const fetchData = async () => {
    try {
      const response = await axios.get('https://node.mumber.com/products_json');

      if (!response.data) {
        throw new Error('No data received');
      }

      setData(response.data);

      
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.success('Data Stored and updated successfully');
    }
  };

  const map_json = {
    float: 'right',
    width: '14%',
    height: '42px',
    borderRadius: '50px',
    color: 'white',
    backgroundColor: '#eb3939',
    fontSize: 'larger',
    fontWeight: '900',
    fontFamily: 'emoji',
    marginTop: '3%',
    border: '0',
  }

  return (
    <>


      {isLoading ? (
        <div className="content-wrapper">
          <Preloader />
        </div>
      ) : (
        <div className="content-wrapper">
          <ToastContainer />
          <p className="all-pro">Products</p>

          <div className="cust-list-box">
            <div className="container-fluid">
              <div className="row"></div>
            </div>
            <input
              type="text"
              style={{
                width: '50%',
                height: '60px',
                borderRadius: '50px',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              className="mb-4 mt-4 mx-3"
              onChange={handleFilter}
              placeholder="Search here by: Phone Number and Area Code"
            />

            <button style={map_json} onClick={fetchData}>
              Create-gz.file
            </button>

            <DataTable
              title="Products"
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              paginationServer
              paginationTotalRows={totalCount}
              paginationPerPage={perPage}
              paginationComponentOptions={{
                rowsPerPageText: 'Rows per page:',
                rangeSeparatorText: 'of',
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'All',
              }}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={(newPerPage) => {
                if (newPerPage === 'all') {
                  setPerPage(data.length);
                } else {
                  setPerPage(newPerPage);
                }
                // Remove the following line to avoid clearing the screen on page change
                // setIsLoading(true);
              }}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={selectedRows.length > 0}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Allproducts;
