import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const Editproducts = () => {


  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const response = await axios.get("https://node.mumber.com/api/getCategories");
      setData(response.data.data);
    } catch (error) {
      console.log(error);

    }
  };

  useEffect(() => {
    loadData();
  }, []);


  const [product, setProduct] = useState({});

  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = window.location.pathname.split("/").pop();
        const response = await axios.get(`https://node.mumber.com/api/product/${id}`);
        setProduct(response.data.data);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  function updateProduct() {
    const phone_no = document.getElementById("phone_no").value;
    const SKU = document.getElementById("SKU").value;
    // const Categories = document.getElementById("Categories").value;
    // const State = document.getElementById("State").value;
    const Nickname = document.getElementById("Nickname").value;
    const area_code = document.getElementById("area_code").value;
    const monthly = document.getElementById("monthly").value;
    const yearly = document.getElementById("yearly").value;
    const Description = document.getElementById("Description").value;
    const nickname = document.getElementById("nickname").value;

    const data = {
      phone_no: phone_no,
      SKU: SKU,
      // Categories: Categories,
      // State: State,
      Nickname: Nickname,
      area_code: area_code,
      monthly: monthly,
      yearly: yearly,
      Description: Description,
      nickname: nickname,
    };

    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/updateProduct/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          toast.success('Data inserted successfully!');

        } else {
          alert("Product update failed");
        }
      })
      .catch((error) => console.error(error));
    // navigate('/allproducts');
    // window.location.reload();
    console.log(product);

  }
  console.log("Data:", data);
  console.log("Product:", product);
  console.log("Product:", product[0]); // Log the entire product object

if (product.length > 0) {
  const { phone_no, SKU, State, City, area_code, monthly, yearly, Description ,Nickname} = product[0];
  console.log("Phone Number:", phone_no);
  console.log("SKU:", SKU);
  console.log("Nickname:", Nickname);
  console.log("State:", State);
  console.log("City:", City);
  console.log("Area Code:", area_code);
  console.log("Monthly:", monthly);
  console.log("Yearly:", yearly);
  console.log("Description:", Description);
  console.log("nickname:", Nickname);
}
function phone(event) {
  const input = event.target;
  const keyCode = event.keyCode || event.which;
  const key = event.key;

  if (!(keyCode === 8 || (keyCode >= 48 && keyCode <= 57))) {
    event.preventDefault();
    return;
  }

  if (input.value.length === 3 && keyCode !== 8) {
    input.value = input.value.slice(0, 3) + '-' + input.value.slice(3);
  } else if (input.value.length === 7 && keyCode !== 8) {
    input.value = input.value.slice(0, 7) + '-' + input.value.slice(7);
  }
}



function formatPhoneNumber(phoneNumber) {
    
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    console.log(phoneNumber);
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  
  return phoneNumber;
}

  return (
    <>
      <div className="content-wrapper">

        <div className="">

          <p className='all-pro'>UPDATE PRODUCT</p>
          <ToastContainer />

          <form>
            <div className='add-pro-box1 mt-5 mb-5'>
              <h2>Basic Information</h2>
              <div>
                <label className='mt-4' htmlFor="phone_no">Phone Number</label><br></br>
                <input className='add-pro-input'
                  type="text"
                  id="phone_no"
                  name="phone_no"
                  defaultValue={formatPhoneNumber(product[0]?.phone_no || "")}
                  onKeyDown={phone}
                  maxlength="12"

                  required />
              </div>


              <label className="mt-3">SKU</label><br></br>
              <input className='add-pro-input' type="text"
                id="SKU"
                name="SKU"
                defaultValue={product[0]?.SKU || ""}
                required />


<label className="mt-3">Nickname</label><br></br>
              <input className='add-pro-input' type="text"
                id="Nickname"
                name="Nickname"
                defaultValue={product[0]?.Nickname || ""}
                required />


              {/* <label className="mt-3">State</label><br></br>
              <input className='add-pro-input' type="text"
                id="State"
                name="State"
                defaultValue={product[0]?.State || ""}
                 />

              <label className="mt-3">City</label><br></br>
              <input className='add-pro-input' type="text"
                id="City"
                name="City"
                defaultValue={product[0]?.City || ""}
                 /> */}


              <label className="mt-3">Area Code</label><br></br>
              <input className='add-pro-input' type="text"
                id="area_code"
                name="area_code"
                defaultValue={product[0]?.area_code || ""}
                required />

              <label className="mt-3">Monthly</label><br></br>
              <input className='add-pro-input' type="text"
                id="monthly"
                name="monthly"
                defaultValue={product[0]?.monthly !== undefined ? parseFloat(product[0]?.monthly).toFixed(2) : ""}
                required />

              <label className="mt-3">Yearly</label><br></br>
              <input className='add-pro-input' type="text"
                id="yearly"
                name="yearly"
                defaultValue={product[0]?.yearly !== undefined ? parseFloat(product[0]?.yearly).toFixed(2) : ""}
                required />

              <label className="mt-3">Keywords/Description</label><br></br>
              <textarea className='add-pro-input'  type="text"
                id="Description"
                name="Description"
                defaultValue={product[0]?.Description || ""}
                required></textarea>
              <label className="mt-3">Nickname</label><br></br>
              <textarea className='add-pro-input' style={{borderRadius: "0px"}} type="text"
                id="nickname"
                name="nickname"
                defaultValue={product[0]?.nickname || ""}
                required></textarea>


            </div>










            <div className='add-pro-box1 mt-5 ' style={{display:"none"}}>





              <label className='mt-4' for="sel1">Product Categories</label>
              <div className="add-pro-input" >
                <select type="text" name="area_code" defaultValue={product.Categories} required
                  className="border-0"
                  style={{
                    width: "98%",
                    height: "43px"
                  }}
                   id="area_code">
                  <option>{product.Categories} </option>
                  {data.map((categorie) => {
                    return (
                      <option key={categorie.Id}>{categorie.Categorie_name}</option>
                    );
                  })}
                </select>



              </div>
            </div>



            <div className='pro-last'>
              {/* <Link to="/allproducts"> */}
              <button type="button" onClick={updateProduct} className='pro-but mt-5 mb-5'>Update</button>
              {/* </Link> */}
            </div>

          </form>
        </div>


        {/* <form>
          <label htmlFor="phone_no">Phone Number:</label>
          <input
            type="text"
            id="phone_no"
            name="phone_no"
            defaultValue={product.phone_no}
            required
          />
          <br />

          <label htmlFor="plan">Plan:</label>
          <input
            type="text"
            id="plan"
            name="plan"
            defaultValue={product.plan}
            required
          />
          <br />

          <label htmlFor="licence_fee">Licence Fee:</label>
          <input
            type="text"
            id="licence_fee"
            name="licence_fee"
            defaultValue={product.licence_fee}
            required
          />
          <br />

          <label htmlFor="SKU">SKU:</label>
          <input
            type="text"
            id="SKU"
            name="SKU"
            defaultValue={product.SKU}
            required
          />
          <br />

          <label htmlFor="Stock">Stock:</label>
          <input
            type="text"
            id="Stock"
            name="Stock"
            defaultValue={product.Stock}
            required
          />
          <br />

          <label htmlFor="Categories">Categories:</label>
          <input
            type="text"
            id="Categories"
            name="Categories"
            defaultValue={product.Categories}
            required
          />
          <br />

         <Link to="/allproducts">
          <button type="button" onClick={updateProduct}>
            Update
          </button>
          </Link>
        </form> */}
      </div>
    </>
  );
};

export default Editproducts;
