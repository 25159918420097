import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Editreview() {
  const [categorie, setCategorie] = useState({});

  const navigate = useNavigate();


  useEffect(() => {
    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/reviews/${id}`)
      .then((response) => response.json())
      .then((data) => setCategorie(data.data))
      .catch((error) => console.error(error));
  }, []);

  function updatereviews() {
    const customer_name = document.getElementById("customer_name").value;
    const description = document.getElementById("description").value;
    const rating = document.getElementById("rating").value;

    const data = {
      customer_name: customer_name,
      description: description,
      rating: rating
    };

    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/updatereviews/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          toast.success('Data inserted successfully!');
        } else {
          alert("update failed");
        }
      })
      .catch((error) => console.error(error));
    // navigate('/customerreview');
    // window.location.reload();


  }
  return (
    <>

      <div className='content-wrapper'>
        <div className='all-pro'>Update Reviews</div>
        <ToastContainer />

        <div className='add-pro-box1 mt-5 mb-5'>
          <h2>Basic Information</h2>
          <div>
            <label className='mt-4' htmlFor="name">Customer Name</label><br></br>
            <input className='add-pro-input'
              type="text"
              id="customer_name"
              name="customer_name"
              defaultValue={categorie.customer_name}
              required />

          </div>

          <div>
            <label className='mt-4' htmlFor="name">Description</label><br></br>
            <input className='add-pro-input'
              type="text"
              id="description"
              name="description"
              defaultValue={categorie.description}
              required />

          </div>

          <div>
            {/* <label className='mt-4' htmlFor="name">rating</label><br></br>
              <input className='add-pro-input'  
              type="text"
              id="rating"
              name="rating"
              defaultValue={categorie.rating}
              required  /> */}

            <label className='mt-1' htmlFor="rating">Rating</label>
            <div className="add-pro-input">
            <select type="text" name="rating" required
               className="border-0"
               style={{
                 width: "98%",
                 height: "43px"
               }} id="rating">
              <option>{categorie.rating}</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>

          </div>
          </div>

          <div className='pro-last'>
            <button type="button" onClick={updatereviews} className='pro-but mt-5 mb-5'>Update</button>
          </div>
        </div>
      </div>


    </>
  )
}

export default Editreview
