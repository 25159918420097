import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Editcategorie = () => {
    const [categorie, setCategorie] = useState({});

  const navigate = useNavigate();


  useEffect(() => {
    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/Categorie/${id}`)
      .then((response) => response.json())
      .then((data) => setCategorie(data.data))
      .catch((error) => console.error(error));
  }, []);

  function updateCategorie() {
    const Categorie_name = document.getElementById("Categorie_name").value;
   
    const data = {
        Categorie_name: Categorie_name,
      
    };

    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/updateCategorie/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          toast.success('Data inserted successfully!');
        } else {
          alert("Categorie update failed");
        }
      })
      .catch((error) => console.error(error));
      // navigate('/categorieslist');
      // window.location.reload();
    

  }
  return (
    <>

    <div className='content-wrapper'>
        <div className='all-pro'>UPDATE CATEGORIES</div>
        <ToastContainer />

        <div className='add-pro-box1 mt-5 mb-5'>
          <h2>Basic Information</h2>
          <div>
            <label className='mt-4' htmlFor="name">Categorie Name</label><br></br>
            <input className='add-pro-input'  
            type="text"
            id="Categorie_name"
            name="Categorie_name"
            defaultValue={categorie.Categorie_name}
            required  />

             </div>

             <div className='pro-last'>
          <button   type="button" onClick={updateCategorie} className='pro-but mt-5 mb-5'>Update</button>
        </div>
    </div>
    </div>
    
      
    </>
  )
}

export default Editcategorie