import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Preloader from '../../Preloader';
import { toast } from 'react-toastify';


import DataTable from 'react-data-table-component';

function Categorieslist() {
  const [data, setData] = useState([]);
  

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get("https://node.mumber.com/api/getCategories");

        // Once the data is fetched, set isLoading to false
        setIsLoading(false);

        // Process the data further if needed
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);



  
  const columns = [
    {
        name: 'ID',
        selector: (row, index) => index + 1,
        sortable: true,
    },
    {
      name: 'Categories Name',
      selector: row => row.Categorie_name,
      sortable: true,
  },
  {
 
    button: true,
    cell: (row) => <Link to={`/updatecategorie/${row.Id}`} >Edit</Link>,
  },
  { 
    
    button: true,
    cell: (row) => <i onClick={() => deleteCategorie(row.Id)} class="fa-solid fa-trash-can"></i>,
  },
];
const [filterText, setFilterText] = useState('');

const handleFilter = (e) => {
  const value = e.target.value;
  setFilterText(value);
};

// const filteredData = data.filter((item) =>
//   item.Categorie_name.toLowerCase().includes(filterText.toLowerCase()) 
// );

const [selectedRows, setSelectedRows] = useState([]);

const handleRowSelected = (rows) => {
  setSelectedRows(rows.selectedRows);
};
    const loadData = async () => {
      try {
        const response = await axios.get("https://node.mumber.com/api/getCategories");
        setData(response.data.data);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch data");
      }
    };
    
    useEffect(() => {
      loadData();
    }, []);
    
  
   
  
    
    const deleteCategorie = async (id) => {
      try {
        if (window.confirm("Are you sure you want to delete this categorie?")) {
          await axios.delete(`https://node.mumber.com/api/removeCategorie/${id}`);
          toast.success("categorie deleted successfully");
          loadData();
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete categorie");
      }
    // window.location.reload();

    };
    return (
      <>
       {isLoading ? (
        <div className="content-wrapper">
        <Preloader />
        </div>
      ) : (
        <div className="content-wrapper">
          <p className='all-pro'>Categories</p>
    
          <div className='cust-list-box'>
            <div className='container-fluid'>
              <div className='row'></div>
            </div>
            <input type="text" style={{width:"50%",
              height:"60px",
              borderRadius:"50px",
              paddingLeft:'20px',
              paddingRight:'20px',
              }}className='mb-4 mt-4 mx-3' onChange={handleFilter} placeholder="Search" />
            <DataTable
              title="Categories"
              columns={columns}
              // data={filteredData}
              selectableRows
              pagination
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={selectedRows.length > 0}
            />
          </div>
        </div>
           )}
      </>
    );
}

export default Categorieslist
