import { useState, useEffect } from 'react';
import axios from 'axios';

function Search() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get('https://node.mumber.com/api/images');
      setImages(response.data.data);
    }
    fetchData();
  }, []);

  return (
    <div>
      <div className='content-wrapper'>
        <h1>Images:</h1>
        {images.map((imageUrl, index) => (
          <img src={imageUrl} key={index} alt={`Image ${index + 1}`} />
        ))}
      </div>
    </div>
  );
}

export default Search;
