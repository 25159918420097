import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Preloader from '../../Preloader';

const Orderlist = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterText, setFilterText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [perPage, setPerPage] = useState(10);

  const [searchText, setSearchText] = useState('');

  const fetchData = async (page, search = '') => {
    try {
      const response = await axios.get(
        `https://node.mumber.com/api/getOrders?page=${page}&perPage=${perPage}&search=${search}`
      );

      setIsLoading(false);
      setData(response.data.data);
      setTotalPages(response.data.total);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, searchText);
  }, [currentPage, searchText, perPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  // const convertToUSDateFormat = (dateString) => {
  //   const date = new Date(dateString);
  //   const formattedDate = new Intl.DateTimeFormat('en-US', {
  //     year: 'numeric',
  //     month: 'numeric',
  //     day: 'numeric',
  //   }).format(date);
  //   return formattedDate;
  // };


  const columns = [
  
    {
      name: 'Order ID',
      selector: row => row.Id,
      sortable: true,
    },
    {
      name: 'Order',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'User Id',
      selector: row => row.user_id,
      sortable: true,
    },
    {
      name: 'OrderDate',
      selector: row => new Date(row.Date).toLocaleDateString(),
      sortable: true,
    }, {
      name: 'Status',
      selector: row => row.Status,
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => {
        const roundedtotal = Math.ceil(row.Total * 100) / 100; 
        return " $ " + roundedtotal.toFixed(2); 
      },
      sortable: true,
    },
   
    {
      button: true,
      cell: (row) => <Link to={`/vieworder/${row.Id}`}>view</Link>,
    },
    {

      button: true,
      cell: (row) => <i onClick={() => deleteorder(row.Id)} class="fa-solid fa-trash-can"></i>,
    },
  ];


 

  const deleteorder = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this customer?")) {
        await axios.delete(`https://node.mumber.com/api/removeOrder/${id}`);
        toast.success("Customer deleted successfully");
        fetchData(currentPage);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete customer");
    }
  };


  const handleFilter = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  
  const filteredData = data.filter((item) => {
    const orderIdMatch = item.Id.toString().includes(searchText);
    const nameMatch = item.name.toLowerCase().includes(searchText.toLowerCase());
    // const dateMatch = item.Date.toLowerCase().includes(searchText.toLowerCase());
    const statusMatch = item.Status.toLowerCase().includes(searchText.toLowerCase());
  
    return orderIdMatch || nameMatch || statusMatch;
  });

console.log(filteredData);


  const handleRowSelected = (rows) => {
    setSelectedRows(rows.selectedRows);
  };

  return (
    <>
      {isLoading ? (
        <div className="content-wrapper">
          <Preloader />
        </div>
      ) : (
        <div className="content-wrapper">
          <p className="all-pro">Orders</p>

          <div className="cust-list-box">
            <div className="container-fluid">
              <div className="row">
               
              </div>
            </div>
            <input
              type="text"
              style={{
                width: "50%",
                height: "60px",
                borderRadius: "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              className="mb-4 mt-4 mx-3"
              onChange={handleFilter}
              placeholder="Search by order ID or customer name  . . . . "
            />
            <DataTable
        title="Orders"
        columns={columns}
        data={filteredData}
        selectableRows
        pagination
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={selectedRows.length > 0}
        paginationServer
        paginationTotalRows={totalPages} // Adjust the total rows count based on perPage
        paginationPerPage={perPage} // Number of items per page
        onChangePage={handlePageChange} // Callback when changing page
        onChangeRowsPerPage={(newPerPage) => {
          if (newPerPage === "all") {
            setPerPage(data.length); // Show all rows if "All" is selected
          } else {
            setPerPage(newPerPage); // Set the selected rows per page value
            setCurrentPage(1); // Reset to the first page when changing rows per page
          }
        }}
        paginationRowsPerPageOptions={[10,15,20,25]} // Available options for rows per page
      />
             
          </div>
        </div>
      )}
    </>
  );
};

export default Orderlist;
