import React, { useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CsvImport() {
  
    const handleDownload = async () => {
      try {
        const response = await fetch('https://node.mumber.com/api/download'); // Replace '/api/download' with your API endpoint
        const data = await response.blob();
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.csv');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading CSV:', error);
      }
    };
    const fileInputRef = useRef(null);
const [selectedFile, setSelectedFile] = useState(null);

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    setSelectedFile(file);
  }
};

const handleImport = async () => {
  try {
    const formData = new FormData();
    formData.append('file', selectedFile);

    const response = await fetch('https://node.mumber.com/api/import', {
      method: 'POST',
      body: formData,
    });

    if (response.status === 200) {
      console.log('Import and save completed successfully');
      toast.success('Data saved successfully');

      // Clear the file input field
      fileInputRef.current.value = null;
    } else if (response.status === 400) {
      console.error('Duplicate phone numbers found in the CSV');
      toast.error('Duplicate phone numbers found in the CSV');
    } else {
      console.error('Error importing CSV:', response.statusText);
      toast.error('Error importing CSV');
    }
  } catch (error) {
    console.error('Error importing CSV:', error);
    toast.error('Error importing CSV');
  }
};

const handleSaveToDatabase = () => {
  if (selectedFile) {
    handleImport();
  } else {
    toast.error('Please select a file');
  }
};

    return (
      <>
        <div className="content-wrapper ">
          <p className='all-pro'>Products</p>
          <ToastContainer />
          <div className='cust-list-box' style={{padding: "25px"}}>
            <div className='container' >
              <div className='row'></div>
            </div>
          
           
            <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        onChange={handleFileChange}
        // style={{ display: 'none' }}
      />
      <br></br>
      <br></br>
      <button onClick={handleSaveToDatabase}>
        {selectedFile ? `Submit: ${selectedFile.name}` : 'Import'}
      </button>
      <button onClick={handleDownload} style={{float: "right"}}>Download Sample</button>
            
          </div>
        </div>
      </>
    );
    
}

export default CsvImport
