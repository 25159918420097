
import './add-reviews.css';

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';                                                                                                                                                                                
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Addreviews() {

  const initialValues = { customer_name: "", description :"", rating:"" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
      const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    const formData = new FormData(e.target);
    const customer_name = formData.get('customer_name');
    const description = formData.get('description');
    const rating = formData.get('rating');
  
    if (!customer_name || !description || !rating) {
      setFormErrors(validate(formValues));
     
      return;
    }
    
      const res = await axios.post("https://node.mumber.com/api/addreview", formValues);
      toast.success('Data inserted successfully!');
      setFormValues({
        customer_name: "",
        description: "",
        rating: ""
      });
//  navigate('/customerreview');  
//  window.location.href = '/customerreview';
      // window.location.reload();
  };
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.customer_name) {
      errors.customer_name = "customer_name is required!";
    }
    if (!values.description) {
      errors.description = "description is required!";
    }
    if (!values.rating) {
      errors.rating = "rating is required!";
    }
    return errors;
  };

  return (
    <div>
    <div className='content-wrapper '>
    

      <div className='add-cust-box1'>
      <div className='all-pro'>ADD-REVIEWS</div>
        <ToastContainer />
        {Object.keys(formErrors).length === 0 && isSubmit ? (
        <div className="ui message success">Custome Review Added successfully</div>
      ) : (
        <pre></pre>
      )}

<form onSubmit={handleSubmit}>
        <div className='add-pro-box1 mt-5 mb-5'>
          <h2>Basic Information</h2>
          <div>
            <label className='mt-4' htmlFor="name">Customer Name</label><br></br>
            <input className='add-pro-input'  
            type="text"
            id="customer_name"
            name="customer_name"
            value={formValues.customer_name} onChange={handleChange} 
              />
            </div>
            <p className="red_colo">{formErrors.customer_name}</p>
            <div>
            <label className='mt-4' htmlFor="name">Description</label><br></br>
            <input className='add-pro-input'  
            type="text"
            id="description"
            name="description"
            value={formValues.description} onChange={handleChange} 
              />
            </div>
            <p className="red_colo">{formErrors.description}</p>
            
            
            <div>
              <label className='mt-1' htmlFor="rating">Rating</label>
              <div className="add-pro-input">
              <select type="text" name="rating" value={formValues.rating} onChange={handleChange} required
               className="border-0"
               style={{
                 width: "98%",
                 height: "43px"
               }} id="rating">
                <option></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>

              </div>
              </div>


            <p className="red_colo">{formErrors.rating}</p>
            </div>


            <div className='pro-last'>
          <button   type="submit" className='pro-but mt-5 mb-5'>Save</button>
        </div>
            </form>



             
    </div>
  </div></div>
  )
}

export default Addreviews