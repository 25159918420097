import React, { Component } from 'react'

export default class Dashboard extends Component {
    render() {
      // const [data, setData] = useState([]);
  
  
      // const loadData = async () => {
      //   try {
      //     const response = await axios.get("https://node.mumber.com/api/countorder");
      //     setData(response.data.data);
      //   } catch (error) {
      //     console.log(error);
      //     toast.error("Failed to fetch data");
      //   }
      // };
      
      // useEffect(() => {
      //   loadData();
      // }, []);
        return (
            <div>
  <div className="content-wrapper mt-3">
    <section className="content">
      <div className="container-fluid">
        {/* Small boxes (Stat box) */}
        <div className="row">
          <div className="col-lg-4 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h3>150</h3>
                <p>New Orders</p>
              </div>
              <div className="icon">
                <i className="ion ion-bag" />
              </div>
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-4 col-6">
            {/* small box */}
            <div className="small-box bg-success">
              <div className="inner">
                <h3>53<sup style={{fontSize: 20}}>%</sup></h3>
                <p> Products</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars" />
              </div>
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-4 col-6">
            {/* small box */}
          
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>44</h3>
                <p> Customers </p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add" />
              </div>
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
         
          {/* ./col */}
        </div>
        {/* /.row */}
        {/* Main row */}
        <div className="row">
         
        
        
        
        <img style={{width: "46%",
    textAlign: "center",
    margin: "auto",
    paddingTop: "100px",
    paddingBottom: "100px"}} src='https://mumber.com/wp-content/uploads/2020/06/logo_res-min-1.png'></img>
          {/* right col (We are only adding the ID to make the widgets sortable)*/}
        
          {/* right col */}
        </div>
        {/* /.row (main row) */}
      </div>{/* /.container-fluid */}
    </section>
    {/* /.content */}
  </div>
</div>

        )
    }
}