import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from "jquery";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const Addblog = () => {
  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const response = await axios.get('https://node.mumber.com/api/getCategories');
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const navigate = useNavigate();

  const [blogData, setBlogData] = useState({
    Title: '',
    // Categories: '',
    Description: '',
    ShortDescription: '',
    Tags: '',
    Meta_keywords: '',
    Meta_description: '',
    publish_date:'',
    image: null,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    setBlogData({
      ...blogData,
      [event.target.name]: event.target.value,
    });
  };

  const handleImageChange = (event) => {
    setBlogData({
      ...blogData,
      image: event.target.files[0],
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', blogData.image);
    formData.append('Title', blogData.Title);
    // formData.append('Categories', blogData.Categories);
    formData.append('Description', blogData.Description);
    formData.append('ShortDescription', blogData.ShortDescription);
    formData.append('Tags', blogData.Tags);
    formData.append('Meta_keywords', blogData.Meta_keywords);
    formData.append('Meta_description', blogData.Meta_description);
    formData.append('publish_date', blogData.publish_date);

    axios
      .post('https://node.mumber.com/api/addblog', formData)
      .then((response) => {
        console.log(response.data);
        toast.success('Data inserted successfully!');

      })
      .catch((error) => {
        console.log(error);
        setErrorMessage('Internal Server Error');

      });
      // navigate('/allblogs');

      // window.location.reload();
  };

  return (
    <div>
      <div className='content-wrapper'>
        <div className='all-pro'>ADD-BLOG</div>
        <ToastContainer />

      <form onSubmit={handleSubmit}>
      <div className='add-pro-box1 mt-5 mb-5'>
      <h2>Basic Information</h2>
            <div>
              <label className='mt-4' htmlFor='name'>
                Title
              </label>
              <br />
              <textarea className='add-pro-input' type='text' name='Title' onChange={handleChange} />
            </div>

            <div>
              <label className='mt-4' htmlFor='name'>
                Short Description
              </label>
              <br />
              <textarea className='add-pro-input' type='text' name='ShortDescription' onChange={handleChange} />
            </div>


            {/* <label className='mt-4' htmlFor='sel1'>
              Categories
            </label>
<div className="add-pro-input">

            <select
              type='text'
              name='Categories'
              onChange={handleChange}
              required
              className="border-0"
                  style={{
                    width: "98%",
                    height: "43px"
                  }}
              id='sel1'
            >
              <option></option>
              {data.map((categorie) => {
                return <option key={categorie.Id}>{categorie.Categorie_name}</option>;
              })}
            </select>
            </div> */}
            <div>
              <label className='mt-4' htmlFor='name'>
                Description
              </label>
              <br />
              <ReactQuill
                id='Description'
                type='text'
                name='Description'
                value={blogData.Description}
                modules={modules}
                onChange={(value) => setBlogData({ ...blogData, Description: value })}
              />
            </div>
            <label className='mt-4' htmlFor='name'>
              Tags
            </label>
            <br />
            <input className='add-pro-input' type='text' name='Tags' onChange={handleChange} />
           
            <div>
              <label className='mt-4'>Meta Description</label>
              <br />
              <ReactQuill
                id='Meta_descriptions'
                type='text'
                value={blogData.Meta_description}
                modules={modules}
                onChange={(value) => setBlogData({ ...blogData, Meta_description: value })}
              />
            </div>
            <label className='mt-4' htmlFor='name'>
              Meta Keywords
            </label>
            <br />
            <input className='add-pro-input' type='text' name='Meta_keywords' onChange={handleChange} />
            
            <label className='mt-4' htmlFor='name'>
            Publish Date
            </label>
            <br />
            <DatePicker
              selected={blogData.publish_date}
              onChange={(date) => setBlogData({ ...blogData, publish_date: date })}
              className='add-pro-input'
              dateFormat='MM/dd/yyyy'
              name='publish_date'
              placeholderText='MM/dd/yyyy'
              autoComplete='off'
              required
            />
            {/* <input className='add-pro-input' type='date' name='publish_date' onChange={handleChange} /> */}
            
            
            
            
            <div>
              <label className='mt-4' htmlFor='name'>
                Image
              </label>
              <br />
              <input type='file' name='image' onChange={handleImageChange} />
            </div>
            <div className='pro-last'>
              <button type='submit' className='pro-but mt-5 mb-5'>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addblog;
