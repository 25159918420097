import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Addcategories = () => {

  const initialValues = {  Categorie_name: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

      const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    const formData = new FormData(e.target);
    const Categorie_name = formData.get('Categorie_name');
 
    if (!Categorie_name ) {
      setFormErrors(validate(formValues));
     
      return;
    }
    
    const res = await axios.post("https://node.mumber.com/api/addcategorie", formValues);
    toast.success('Data inserted successfully!');
    setFormValues({
      Categorie_name: ""
   });
      setIsSubmit(true);
  };
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.Categorie_name) {
      errors.Categorie_name = "Categorie_name is required!";
    }
  
    return errors;
  };


  return (
    <div>
        <div className='content-wrapper'>
        <div className='all-pro'>ADD-CATEGORIES</div>

        <ToastContainer />
        <form onSubmit={handleSubmit}>
        <div className='add-pro-box1 mt-5 mb-5'>
          <h2>Basic Information</h2>
          {Object.keys(formErrors).length === 0 && isSubmit ? (
        <div className="ui message success">Categorie Added successfully</div>
      ) : (
        <pre></pre>
      )}
          <div>
            <label className='mt-4' htmlFor="name">Categorie Name</label><br></br>
            <input className='add-pro-input'  
            type="text"
            id="Categorie_name"
            name="Categorie_name"
            value={formValues.Categorie_name} onChange={handleChange} 
              />
            </div>
            <p className="red_colo">{formErrors.Categorie_name}</p>

            </div>


            <div className='pro-last'>
          <button   type="submit" className='pro-but mt-5 mb-5'>Save</button>
        </div>
            </form>
        </div>
    </div>
  )
}

export default Addcategories
