import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const Addfaq = () => {
  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const response = await axios.get('https://node.mumber.com/api/getCategories');
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const navigate = useNavigate();

  const [faqData, setfaqData] = useState({
    Title: '',
    Description: '',
    ShortDescription: '',
    image: null,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    setfaqData({
      ...faqData,
      [event.target.name]: event.target.value,
    });
  };

  const handleImageChange = (event) => {
    setfaqData({
      ...faqData,
      image: event.target.files[0],
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', faqData.image);
    formData.append('Title', faqData.Title);
    formData.append('Description', faqData.Description);
    formData.append('ShortDescription', faqData.ShortDescription);

    axios
      .post('https://node.mumber.com/api/addfaq', formData)
      .then((response) => {
        console.log(response.data);
        // do something after successful submission
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage('Internal Server Error');
      });
    navigate('/allfaqs');
    window.location.reload();
  };

  return (
    <div>
      <div className='content-wrapper'>
        <div className='all-pro'>ADD-FAQ</div>
        <form onSubmit={handleSubmit}>
          <div className='add-pro-box1 mt-5 mb-5'>
            <h2>Basic Information</h2>
            <div>
              <label className='mt-4' htmlFor='name'>
                Title
              </label>
              <br />
              <textarea className='add-pro-input' type='text' name='Title' onChange={handleChange} />
            </div>

            <div>
              <label className='mt-4' htmlFor='name'>
                Short Description
              </label>
              <br />
              <textarea className='add-pro-input' type='text' name='ShortDescription' onChange={handleChange} />
            </div>


           
            <div>
              <label className='mt-4' htmlFor='name'>
                Description
              </label>
              <br />
              <ReactQuill
                id='Description'
                type='text'
                name='Description'
                value={faqData.Description}
                modules={modules}
                onChange={(value) => setfaqData({ ...faqData, Description: value })}
              />
            </div>
           
            
            
            <div>
              <label className='mt-4' htmlFor='name'>
                Image
              </label>
              <br />
              <input type='file' name='image' onChange={handleImageChange} />
            </div>
            <div className='pro-last'>
              <button type='submit' className='pro-but mt-5 mb-5'>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addfaq;
