import React from 'react'
import './Preloader.css'

const Preloader = () => {
  return (
    <>
      
    <div className='preload'>
      <img src='logo_res-min-1.png'></img>
    </div>
    
    </>
  )
}

export default Preloader