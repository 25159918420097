import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import emailjs from 'emailjs-com';
import './add-order.css'
import { Link, useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Addorder = () => {
  const initialValues = {
    name: "",
    OrderDate: "",
    Status: "",
    Total: ""
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get('name');
    const user_id = formData.get('user_id');
    const OrderDate = formData.get('OrderDate');
    const Status = formData.get('Status');
    const Total = formData.get('Total');

    if (!name || !user_id || !OrderDate || !Status || !Total) {
      setFormErrors(validate(formValues));
      return;
    }

    const res = await axios.post("https://node.mumber.com/api/addorder", formValues);
    toast.success('Data inserted successfully!');

    const emailParams = {
      name,
      user_id,
      OrderDate,
      Status,
      Total
    };

    emailjs.send('service_vafdalo', 'template_wyfafr9', emailParams, 'fx1nGNdZvCOZjCnRL')
      .then((response) => {
        console.log('Email sent!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });

    setFormValues(initialValues);
    setIsSubmit(true);
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "name is required!";
    }
    if (!values.OrderDate) {
      errors.OrderDate = "Date is required!";
    }
    if (!values.Status) {
      errors.Status = "Status is required!";
    }
    if (!values.Total) {
      errors.Total = "Total is required!";
    }
    return errors;
  };

  return (
    <div>
      <div className='content-wrapper'>
        <div className='all-pro'>ADD-ORDER</div>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className='add-pro-box1 mt-5 mb-5'>
            <h2>Basic Information</h2>
            {Object.keys(formErrors).length === 0 && isSubmit ? (
              <div className="ui message success">Order Added successfully</div>
            ) : (
              <pre></pre>
            )}
            <div>
              <label className='mt-4' htmlFor="name">Order Name</label><br></br>
              <input className='add-pro-input'
                type="text"
                id="name"
                name="name"
                value={formValues.name} onChange={handleChange}
              />
              <p className="red_colo">{formErrors.name}</p>

              <label className='mt-4' htmlFor="name">User Id</label><br></br>
              <input className='add-pro-input'
                type="text"
                id="user_id"
                name="user_id"
                value={formValues.user_id} onChange={handleChange}
              />
              <p className="red_colo">{formErrors.user_id}</p>

              <label className='mt-4' htmlFor="Date">Date</label><br></br>
              <div className="add-pro-input">
              <input  className="border-0"
                style={{
                  width: "98%",
                  height: "43px"
                }}
                type="date"
                id="OrderDate"
                pattern="\d{4}-\d{2}-\d{2}"
                name="OrderDate"
                value={formValues.OrderDate} onChange={handleChange}
              />
              </div>
              <p className="red_colo">{formErrors.OrderDate}</p>

              <label className='mt-1' htmlFor="Status">Status</label>
              <div className="add-pro-input">
              <select type="text" name="Status" value={formValues.Status} onChange={handleChange} required
                className="border-0"
                style={{
                  width: "98%",
                  height: "43px"
                }} id="Status">
                <option>Pending</option>
                <option>In process</option>
                <option>Delivered</option>
              </select>
              </div>
              <p className="red_colo">{formErrors.Status}</p>

              <label className='mt-4' htmlFor="name">Total</label><br></br>
              <input className='add-pro-input'
                type="text"
                id="Total"
                name="Total"
                value={formValues.Total} onChange={handleChange}
              />
              <p className="red_colo">{formErrors.Total}</p>
            </div>
          </div>

          <div className='pro-last'>
            <button type="submit" className='pro-but mt-5 mb-5'>Save</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Addorder;
