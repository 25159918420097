import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Auth_nav from "./nav_auth_buttons";


export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: null
    };
  }

  toggleMenu = (menuName) => {
    this.setState((prevState) => ({
      activeMenu: prevState.activeMenu === menuName ? null : menuName
    }));
  };

  render() {
    const { activeMenu } = this.state;

    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <div className="sidebar">
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image"></div>
              <div className="info">
              <NavLink to="/" className="d-block">
                
                <img style={{ width:"83%" }} src='https://mumber.com/wp-content/uploads/2020/06/logo_res-min-1.png'></img>
                </NavLink>
              </div>
            </div>
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                 <li className="nav-item has-treeview">
            <NavLink to="/" className="nav-link" activeClassName="active">
              <i className="nav-icon fa-solid fa-house"></i>
              <p>Dashboard</p>
            </NavLink>
          </li>
          {/* <li className="nav-item has-treeview">
            <NavLink to="/Notification" className="nav-link" activeClassName="active">
              <i className="nav-icon fa-solid fa-house"></i>
              <p>Notification</p>
            </NavLink>
          </li> */}
                <li className={`nav-item has-treeview ${activeMenu === 'products' ? 'menu-open' : ''}`}>
            <a href="#" className="nav-link" onClick={() => this.toggleMenu('products')}>
                    <i className="nav-icon fas fa-copy"></i>
                    <p>
                      Products
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/allproducts" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Products</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/addproducts" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Products</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/CsvImport" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Import Products</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>


                <li className={`nav-item has-treeview ${activeMenu === 'Customers' ? 'menu-open' : ''}`}>
            <a href="#" className="nav-link" onClick={() => this.toggleMenu('Customers')}>
                    <i className="nav-icon fa-solid fa-user"></i>
                    <p>
                      Customers
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/allcustomers" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Customers</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/addcustomers" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Customers</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/upcominginvoices" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Upcoming Invoices</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>


                <li className={`nav-item has-treeview ${activeMenu === 'Orders' ? 'menu-open' : ''}`}>
            <a href="#" className="nav-link" onClick={() => this.toggleMenu('Orders')}>
                    <i className="nav-icon fa-sharp fa-solid fa-cart-shopping"></i>
                    <p>
                      Orders
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/orderlist" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Orders</p>
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink to="/addorder" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Orders</p>
                      </NavLink>
                    </li> */}
                  </ul>
                </li>


                <li className={`nav-item has-treeview ${activeMenu === 'Blogs' ? 'menu-open' : ''}`}>
            <a href="#" className="nav-link" onClick={() => this.toggleMenu('Blogs')}>
                    <i className="nav-icon fas fa-chart-pie"></i>
                    <p>
                      Blogs
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/allblogs" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Blogs</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/addblog" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Blogs</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>



                {/* <li className={`nav-item has-treeview ${activeMenu === 'Categories' ? 'menu-open' : ''}`}>
            <a href="#" className="nav-link" onClick={() => this.toggleMenu('Categories')}>
                    <i className="nav-icon fa-solid fa-users"></i>
                    <p>
                      Categories
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/categorieslist" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Categories</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/addcategories" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Categories</p>
                      </NavLink>
                    </li>
                  </ul>
                </li> */}

                <li className={`nav-item has-treeview ${activeMenu == 'Review' ? 'menu-open' : ''}`}>
            <a href="#" className="nav-link" onClick={() => this.toggleMenu('Review')}>
                    <i className="nav-icon fa-solid fa-users"></i>
                    <p>
                    Customers Review
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
              <li className="nav-item">
                <NavLink to="customerreview" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Review List</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="addreviews" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Review</p>
                </NavLink>
              </li>
            </ul>

                </li>


                <li className={`nav-item has-treeview ${activeMenu == 'FAQ' ? 'menu-open' : ''}`}>
            <a href="#" className="nav-link" onClick={() => this.toggleMenu('FAQ')}>
                    <i className="nav-icon fa-solid fa-users"></i>
                    <p>
                     FAQ
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/allfaqs" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>All FAQ'S </p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/addfaq" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add FAQ</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <NavLink to="/gateway_setting" className="nav-link" activeClassName="active">
              <i className="nav-icon fas fa-copy"></i>
              <p> Gateway Settings</p>
            </NavLink>

              
              </ul>
            </nav>
          </div>
        </aside>
      </div>
    );
  }
}