import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './customer-review.css';
import DataTable from 'react-data-table-component';
import Preloader from '../../Preloader';

function Customerreview() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get("https://node.mumber.com/api/getreview");

        // Once the data is fetched, set isLoading to false
        setIsLoading(false);

        // Process the data further if needed
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  
  const [data, setData] = useState([]);
  
  const columns = [
    {
        name: 'ID',
        selector: (row, index) => index + 1,
        sortable: true,
    },
    {
      name: 'Customer Name',
      selector: row => row.customer_name,
      sortable: true,
  },
    {
        name: 'Description',
        selector: row => row.description,
        sortable: true,
    },{
      name: 'Rating',
      selector: row => row.rating,
      sortable: true,
  },
  {
 
    button: true,
    cell: (row) => <Link to={`/updatereview/${row.id}`} >Edit</Link>,
  },
  {
    
    button: true,
    cell: (row) => <i onClick={() => deletereview(row.id)} class="fa-solid fa-trash-can"></i>,
  },
];
const [filterText, setFilterText] = useState('');

  const handleFilter = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  const filteredData = data.filter((item) =>
    item.customer_name.toLowerCase().includes(filterText.toLowerCase())
    || item.description.toLowerCase().includes(filterText.toLowerCase())
  );

const [selectedRows, setSelectedRows] = useState([]);

const handleRowSelected = (rows) => {
  setSelectedRows(rows.selectedRows);
};
    const loadData = async () => {
      try {
        const response = await axios.get("https://node.mumber.com/api/getreview");
        setData(response.data.data);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch data");
      }
    };
    
    useEffect(() => {
      loadData();
    }, []);
    
  
   
  
    const deletereview = async (id) => {
      try {
        if (window.confirm("Are you sure you want to delete this review")) {
          await axios.delete(`https://node.mumber.com/api/removereview/${id}`);
          toast.success("review deleted successfully");
          loadData();
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete");
      }
    window.location.reload();

    };
  return (
    <>
     {isLoading ? (
        <div className="content-wrapper">
        <Preloader />
        </div>
      ) : (
            <div className="content-wrapper">
                <p className='all-pro'>Customer Reviews</p>


                <div className='cust-list-box'>
               <div className='container-fluid'>
                <div className='row'>
                {/* <form class="d-flex" role="search">
      <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
      <button class="btn all-pro-search" type="submit">Search</button>
    </form> */}
                </div>
               </div>
               <input type="text" style={{width:"50%",
              height:"60px",
              borderRadius:"50px",
              paddingLeft:'20px',
              paddingRight:'20px',
              }} className='mb-4 mt-4 mx-3' onChange={handleFilter} placeholder="Search by Name and Description" />
               <DataTable
      title="Customer Reviews"
      columns={columns}
      data={filteredData}
      selectableRows
      pagination
      onSelectedRowsChange={handleRowSelected}
      clearSelectedRows={selectedRows.length > 0}
    />

            </div>
            </div>
 )}
        </>
  )
}

export default Customerreview
