import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Editorder = () => {
  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/order/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setOrder(data.data);
        if (data.data.user_id) {
          loadData(data.data.user_id);
        }
      })
      .catch((error) => console.error(error));
  }, []);
  
  const loadData = async (user_id) => {
    try {
      const response = await axios.get(`https://node.mumber.com/orders/${user_id}`);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch data");
    }
  };

  function updateOrder() {
    const name = document.getElementById("name").value;
    const OrderDate = document.getElementById("OrderDate").value;
    const Status = document.getElementById("Status").value;
    const Total = document.getElementById("Total").value;
   
    const data = {
      name: name,
      OrderDate: OrderDate,
      Status: Status,
      Total: Total,
    };

    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/updateOrder/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          toast.success('Data inserted successfully!');
        } else {
          alert("Order update failed");
        }
      })
      .catch((error) => console.error(error));
    // window.location.reload();

      // navigate('/orderlist');


  }
  return (
    <>

    <div className='content-wrapper'>
        <div className='all-pro'>UPDATE ORDER</div>
        <ToastContainer />
        <div className='add-pro-box1 mt-5 mb-5'>
          <h2>Basic Information</h2>
<br></br>
          <h5>Customer Name</h5>
          {data.map((data) => {
                return (
          <h5 style={{marginLeft: "167px",
            marginTop: "-31px"}} key={data.id}>{data.name}</h5>
          );
        })}

          <div>
            <label className='mt-4' htmlFor="name">OrderName</label><br></br>
            <input className='add-pro-input'  
            type="text"
            id="name"
            name="name"
            defaultValue={order.name}
            required  />




<label className='mt-4' htmlFor="Date">Date</label><br></br>
<div className="add-pro-input">
<input
  className="border-0"
                  style={{
                    width: "98%",
                    height: "43px"
                  }}
  type="date"
  id="OrderDate"
  name="OrderDate"
  defaultValue={order.OrderDate ? order.OrderDate.slice(0, 10) : ''}
  required
/>
</div>





<label className='mt-1' for="Status">Status</label>
<div className="add-pro-input">
          <select type="text" name="Status"  
           required
           className="border-0"
           style={{
             width: "98%",
             height: "43px"
           }}
            id="Status">
                <option>{order.Status}</option>
                <option>Pending</option>
                <option>Purchased</option>
                
          </select>
</div>


<label className='mt-4' htmlFor="name">Total</label><br></br>
            <input className='add-pro-input'  
            type="text"
            id="Total"
            name="Total"
            defaultValue={order.Total}
            required  />
             </div>
             </div>

             <div className='pro-last'>
          <button   type="button" onClick={updateOrder} className='pro-but mt-5 mb-5'>Update</button>
        </div>
    </div>
      
    </>
  )
}

export default Editorder
