import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Dash = () => {
  const [totalOrders, setTotalOrders] = useState(0);

  useEffect(() => {
    fetchTotalOrders();
  }, []);

  const fetchTotalOrders = () => {
    fetch('https://node.mumber.com/api/countorder')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTotalOrders(data.totalCount);
        } else {
          console.log(data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };




  const [totalCustomers, setTotalCustomers] = useState(0);

  useEffect(() => {
    fetchTotalCustomers();
  }, []);

  const fetchTotalCustomers = () => {
    fetch('https://node.mumber.com/api/countcustomers')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTotalCustomers(data.totalCount);
        } else {
          console.log(data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };





  const [totalproducts, setTotalproducts] = useState(0);

  useEffect(() => {
    fetchTotalproducts();
  }, []);

  const fetchTotalproducts = () => {
    fetch('https://node.mumber.com/api/countproducts')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTotalproducts(data.totalCount);
        } else {
          console.log(data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
    return (
        <>

            <div className="content-wrapper mt-3">
                <section className="content">
                    
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">

                            <div className="col-lg-4 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                    <h3>{totalOrders}</h3>
                                        <p>New Orders</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div>
                                    <Link to="/orderlist">
                                        <a href="#" style={{
                                            display: "block",
                                            color:"white",
                                            padding: "3px 0",
                                            position: "relative",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            zIndex: "10"
                                        }} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </Link>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-4 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                    <h3 >{totalproducts}</h3>
                                        <p style={{color:"white"}}> Products</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <Link to="/allproducts">
                                        <a href="#" style={{
                                            display: "block",
                                            color:"white",
                                            padding: "3px 0",
                                            position: "relative",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            zIndex: "10"
                                        }} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </Link>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-4 col-6">
                                {/* small box */}

                                <div className="small-box bg-warning">
                                    <div className="inner">
                                    <h3 style={{color:"white"}}>{totalCustomers}</h3>
                                        <p style={{color:"white"}}> Customers </p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div>
                                    <Link to="/allcustomers">
                                        <a href="#" style={{
                                            display: "block",
                                            padding: "3px 0",
                                            color:"white",
                                            position: "relative",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            zIndex: "10"
                                        }} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </Link>
                                </div>
                            </div>
                            {/* ./col */}

                            {/* ./col */}
                        </div>
                        {/* /.row */}
                        {/* Main row */}
                        <div className="row">




                            {/* <img style={{ width: "46%",
    textAlign: "center",
    margin: "auto",
    paddingTop: "100px",
    paddingBottom: "100px" }} src='https://mumber.com/wp-content/uploads/2020/06/logo_res-min-1.png'></img> */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}

                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>


        </>
    )
}

export default Dash