import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const Editblog = () => {
  const [data, setData] = useState([]);
  const [blog, setBlog] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://node.mumber.com/api/getCategories");
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const id = window.location.pathname.split("/").pop();
        const response = await axios.get(`https://node.mumber.com/api/blog/${id}`);
        setBlog(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlog();
  }, []);


  
  const updateBlog = () => {
    const Title = document.getElementById("Title").value;
    // const Categories = document.getElementById("Categories").value;
    const Description = blog.Description;
    const ShortDescription = blog.ShortDescription;
    const Tags = document.getElementById("Tags").value;
    const Meta_keywords = document.getElementById("Meta_keywords").value;
    const Meta_description = blog.Meta_description;
    // const publish_date = blog.publish_date ? blog.publish_date.split('T')[0] : '';
    console.log("blog.publish_date",blog.publish_date);

    const publish_date = document.getElementById("publish_date").value;
    // const dayInput = document.getElementById("dayInput");
    // const monthInput = document.getElementById("monthInput");
    // const yearInput = document.getElementById("yearInput");
    
    // const publish_date = dayInput.value && monthInput.value && yearInput.value
    //   ? `${yearInput.value}-${monthInput.value}-${dayInput.value}`
    //   : null;

    const formData = new FormData();
    formData.append("Title", Title);
    // formData.append("Categories", Categories);
    formData.append("Description", Description);
    formData.append("ShortDescription", ShortDescription);
    formData.append("Tags", Tags);
    formData.append("Meta_keywords", Meta_keywords);
    formData.append("Meta_description", Meta_description);
    formData.append("publish_date", publish_date);
    formData.append("image", imageFile);

    const id = window.location.pathname.split("/").pop();
    axios.put(`https://node.mumber.com/api/updateBlog/${id}`, formData)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          toast.success('Data inserted successfully!');
          // navigate("/allblogs");
        } else {
          alert("Blog update failed");
        }
      })
      .catch((error) => console.error(error));
  };


  const handleChangeShortDescription = (e) => {
    const value = e.target.value;
    setBlog({ ...blog, ShortDescription: value });
  };

  const handleChangeMetaDescription = (value) => {
    setBlog({ ...blog, Meta_description: value });
  };

  const handleChangeDescription = (value) => {
    setBlog({ ...blog, Description: value });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  console.log("publish_date",FormData.publish_date);
  return (
    <>
      <div className='content-wrapper'>
        <div className='all-pro'>UPDATE BLOG</div>
        <ToastContainer />

        <div className='add-pro-box1 mt-5 mb-5'>
          <h2>Basic Information</h2>
          <div>
            <label className='mt-4' htmlFor="Title">Title</label><br />
            <textarea className='add-pro-input'
              type="text"
              id="Title"
              name="Title"
              defaultValue={blog.Title}
              required />

            <label className='mt-4' htmlFor="ShortDescription">Short Description</label><br />
            <textarea className='add-pro-input'
              type="text"
              id="ShortDescription"
              name="ShortDescription"
              defaultValue={blog.ShortDescription}
              onChange={handleChangeShortDescription}
              required />

            {/* <label className='mt-4' htmlFor="Categories">Categories</label><br />
<div className="add-pro-input">
            <select name="Categories" defaultValue={blog.Categories} className="border-0"
                  style={{
                    width: "98%",
                    height: "43px"
                  }} id="Categories">
              <option>{blog.Categories}</option>
              {data.map((categorie) => (
                <option key={categorie.Id}>{categorie.Categorie_name}</option>
              ))}
            </select>
            </div> */}

            <label className='mt-4' htmlFor="Description">Description</label><br />
            <ReactQuill
              id="Description"
              name="Description"
              modules={modules}
              value={blog.Description}
              onChange={handleChangeDescription}
              required />

            <label className='mt-4' htmlFor="Tags">Tags</label><br />
            <input className='add-pro-input'
              type="text"
              id="Tags"
              name="Tags"
              defaultValue={blog.Tags}
              required />

            <label className='mt-4' htmlFor="Meta_description">Meta description</label><br />
            <ReactQuill
              id="Meta_description"
              name="Meta_description"
              modules={modules}
              value={blog.Meta_description}
              onChange={handleChangeMetaDescription}
              required />

            <label className='mt-4' htmlFor="Meta_keywords">Meta keywords</label><br />
            <input className='add-pro-input'
              type="text"
              id="Meta_keywords"
              name="Meta_keywords"
              defaultValue={blog.Meta_keywords}
              required />

            <label className='mt-4' htmlFor="publish_date">Publish Date*</label><br />
            {/* <input className='add-pro-input'
              type="date"
              id="publish_date"
              name="publish_date"
              defaultValue={blog.publish_date ? blog.publish_date.split('T')[0] : ''}
              required /> */}

<DatePicker
        selected={blog.publish_date ? new Date(blog.publish_date) : null}
        onChange={(date) => setBlog({ ...blog, publish_date: date })}
        dateFormat="MM/dd/yyyy"
        id="publish_date"
        name="publish_date"
        className='add-pro-input'
        required
      />
<br></br>
            {/* <div className="d-flex">
              <div>
              <label style={{color:"grey"}}>Day</label>
            <input
              className='add-pro-input'
              type="number"
              id="dayInput"
              name="dayInput"
              defaultValue={blog.publish_date ? new Date(blog.publish_date).getDate() : ''}
              placeholder="Day"
              onChange={(e) => setBlog({ ...blog, dayInput: e.target.value })}
              required
            />
            </div>
            <div>
              <label style={{color:"grey"}}>Month</label>
            <input
              className='add-pro-input'
              type="number"
              id="monthInput"
              name="monthInput"
              placeholder="Month"
              defaultValue={blog.publish_date ? new Date(blog.publish_date).getMonth() + 1 : ''}
              onChange={(e) => setBlog({ ...blog, monthInput: e.target.value })}
              required
            />
            </div>
            <div>
              <label style={{color:"grey"}}>Year</label>
            <input
              className='add-pro-input'
              type="number"
              id="yearInput"
              name="yearInput"
              placeholder="Year"
              defaultValue={blog.publish_date ? new Date(blog.publish_date).getFullYear() : ''}
              onChange={(e) => setBlog({ ...blog, yearInput: e.target.value })}
              required
            />
            </div>
            </div> */}

            <label className='mt-4' htmlFor="image">Image</label><br />
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
            <br />
            <img className="mt-4 w-50" src={`https://node.mumber.com/uploads/${blog.images}`} alt="No Image" />
          </div>
        </div>

        <div className='pro-last'>
          <button type="button" onClick={updateBlog} className='pro-but mt-5 mb-5'>Update</button>
        </div>
      </div>
    </>
  );
};

export default Editblog;
