import React from 'react'

const Allpartner = () => {
  return (
    <div>
      <div className='content-wrapper'>
        <div className='all-pro'>
            Partner List
        </div>
      </div>
    </div>
  )
}

export default Allpartner
