import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Editcustomer = () => {


    const [customer, setCustomer] = useState({});

    const navigate = useNavigate();


    useEffect(() => {
        const id = window.location.pathname.split("/").pop();
        fetch(`https://node.mumber.com/api/customer/${id}`)
            .then((response) => response.json())
            .then((data) => setCustomer(data.data))
            .catch((error) => console.error(error));
    }, []);

    function updatecustomer() {
        const first_name = document.getElementById("first_name").value;
        const last_name = document.getElementById("last_name").value;
        const company_name = document.getElementById("company_name").value;
        const street_address = document.getElementById("street_address").value;
        const town = document.getElementById("town").value;
        const state = document.getElementById("state").value;
        const ZIP_Code = document.getElementById("ZIP_Code").value;
        const phone = document.getElementById("phone").value;
        const email_address = document.getElementById("email_address").value;

        const data = {
            first_name: first_name,
            last_name: last_name,
            company_name: company_name,
            street_address: street_address,
            town: town,
            state: state,
            ZIP_Code: ZIP_Code,
            phone: phone,
            email_address: email_address,
        };

        const id = window.location.pathname.split("/").pop();
        fetch(`https://node.mumber.com/api/updatecustomer/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })

            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.success) {
                    toast.success('Data update successfully!');
                } else {
                    toast.success("customer update failed");
                }
            })
            .catch((error) => console.error(error));
        // navigate('/allcustomers');
        // window.location.reload();
        console.log(customer);

    }


    return (
        <>

            <div className="content-wrapper">

                <div className="">

                    <p className='all-pro'>UPDATE CUSTOMER</p>
                    <ToastContainer />
                    <form>
                        <div className='add-pro-box1 mt-5 mb-5'>
                            <h2>Basic Information</h2>
                            <div>
                                <label className='mt-4' htmlFor="first_name">First Name</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    defaultValue={customer.first_name}
                                    required />
                            </div>


                            <div>
                                <label className='mt-4' htmlFor="last_name">Last Name</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    defaultValue={customer.last_name}
                                    required />
                            </div>


                            <div>
                                <label className='mt-4' htmlFor="company_name">Company Name</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="company_name"
                                    name="company_name"
                                    defaultValue={customer.company_name}
                                    required />
                            </div>

                            <div>
                                <label className='mt-4' htmlFor="street_address">Street Address</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="street_address"
                                    name="street_address"
                                    defaultValue={customer.street_address}
                                    required />
                            </div>

                            <div>
                                <label className='mt-4' htmlFor="town">City</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="town"
                                    name="town"
                                    defaultValue={customer.town}
                                    required />
                            </div>

                            <div>
                                <label className='mt-4' htmlFor="phone_no">State</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="state"
                                    name="state"
                                    defaultValue={customer.state}
                                    required />
                            </div>

                            <div>
                                <label className='mt-4' htmlFor="ZIP_Code">ZIP Code</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="ZIP_Code"
                                    name="ZIP_Code"
                                    defaultValue={customer.ZIP_Code}
                                    required />
                            </div>

                            <div>
                                <label className='mt-4' htmlFor="phone">Phone Number</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    defaultValue={customer.phone}
                                    required />
                            </div>

                            <div>
                                <label className='mt-4' htmlFor="email_address">Email Address</label><br></br>
                                <input className='add-pro-input'
                                    type="text"
                                    id="email_address"
                                    name="email_address"
                                    defaultValue={customer.email_address}
                                    required />
                            </div>



                        </div>
                        <div className='pro-last'>
                            <button type="button" onClick={updatecustomer} className='pro-but mt-5 mb-5'>Update</button>
                        </div>

                    </form>
                </div>



            </div>

        </>
    )
}

export default Editcustomer