import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: null
    };
  }

  toggleMenu = (menuName) => {
    this.setState((prevState) => ({
      activeMenu: prevState.activeMenu === menuName ? null : menuName
    }));
  };

  render() {
    const { activeMenu } = this.state;

    return (
      <nav className="mt-2">
        <ul
          className="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li className="nav-item has-treeview">
            <NavLink to="/" className="nav-link" activeClassName="active">
              <i className="nav-icon fa-solid fa-house"></i>
              <p>Dashboard</p>
            </NavLink>
          </li>

          <li className={`nav-item has-treeview ${activeMenu === 'products' ? 'menu-open' : ''}`}>
            <a href="#as" className="nav-link" onClick={() => this.toggleMenu('products')}>
              <i className="nav-icon fas fa-copy"></i>
              <p>
                Products
                <i className="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <NavLink to="/allproducts" className="nav-link" activeClassName="active">
                  <i className="far fa-circle nav-icon"></i>
                  <p>All Products</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/addproducts" className="nav-link" activeClassName="active">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Add Products</p>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className={`nav-item has-treeview ${activeMenu === 'order' ? 'menu-open' : ''}`}>
            <a href="#as" className="nav-link" onClick={() => this.toggleMenu('order')}>
              <i className="nav-icon fas fa-copy"></i>
              <p>
                Products
                <i className="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <NavLink to="/allproducts" className="nav-link" activeClassName="active">
                  <i className="far fa-circle nav-icon"></i>
                  <p>All Products</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/addproducts" className="nav-link" activeClassName="active">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Add Products</p>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Repeat the above structure for other menu items */}
          
        </ul>
      </nav>
    );
  }
}

export default Time;
