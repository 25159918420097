import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const params = useParams();
  const [userData, setUserData] = useState({ name: '', email: '', password: '' });

const [newPassword, setNewPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
 
const fetchUserData = async () => {
  try {
    const response = await fetch(`https://node.mumber.com/api/users/${params.id}`);
    if (response.ok) {
      const data = await response.json();
      setUserData(data);
    } else {
        toast.error('Error fetching user data:', response.statusText);
    }
  } catch (error) {
    toast.error('Error fetching user data:', error);
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();

  if (newPassword !== confirmPassword) {
    toast.error("Passwords don't match");
    return;
  }

  if (newPassword.length < 8 || newPassword.length > 16) {
    toast.error('Password must be between 8 and 16 characters');
    return;
  }

  try {
    const response = await fetch(`https://node.mumber.com/api/users/${params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: newPassword,
      }),
    });

    if (response.ok) {
        toast.success('Password updated successfully');
      setUserData({ ...userData, password: newPassword });
      setNewPassword('');
      setConfirmPassword('');
    } else {
      console.error('Error updating password:', response.statusText);
    }
  } catch (error) {
    console.error('Error updating password:', error);
  }
};


useEffect(() => {
  fetchUserData();
}, [params.id]);

return (
    <div>
      <div className='content-wrapper'>
        <div className='all-pro'>Profile</div>
        <ToastContainer />

        <form onSubmit={handleSubmit}>
        <div className='add-pro-box1 mt-5 mb-5'>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={userData.name}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={userData.email}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={userData.password}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Save
        </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default Profile;