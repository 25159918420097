import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Dropdown, Badge } from 'react-bootstrap';
import { FaBell } from 'react-icons/fa';
const Auth_nav = ()=> {
    
    
  const navigate = useNavigate();


  const usersString = window.localStorage.getItem('users');
  const users = window.localStorage.getItem('users');
  const [notifications, setNotifications] = useState([]);

  // ... Other code

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userId = JSON.parse(users)[0].id;
        const response = await fetch(`https://node.mumber.com/notifications`);
        const data = await response.json();
        
        setNotifications(data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }

     
    };

    fetchNotifications();
  }, [users]);

  const handleLogout = () => {

    
    // remove the token and user from the session storage
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('users');
    window.localStorage.removeItem('isLoggedIn');
    navigate('/Login');  
  }
    
    const usertoken = window.localStorage.getItem('token');
    if(usertoken){
      const parsedUsers = JSON.parse(users);

      const ids = parsedUsers.map(user => user.id);
      
      console.log(ids);
        return(
                // <ul className="d-flex justify-content-between align-items-center">
                //     <li onClick={handleLogout}><a role="button">Logout</a></li>
                //    <Link to="/Profile/10"><li><a role="button">Profile</a></li></Link> 
                // </ul>

<ul className="navbar-nav">

<Link to={`/Profile/${ids}`}><li className="nav-item  d-sm-inline-block nav-link">
Profile
</li></Link> 
<li onClick={handleLogout} className="nav-item d-sm-inline-block nav-link">
 Logout
</li>
<li>
          {/* <Dropdown>
  <Dropdown.Toggle as={CustomToggle} id="notification-bell">
    <FaBell />
    {notifications.length > 0 && (
      <Badge variant="danger" className="notification-badge">
        {notifications.length}
      </Badge>
    )}
  </Dropdown.Toggle> */}
  {/* <Dropdown.Menu >
    {notifications.map(notification => (
      <Dropdown.Item style={{ backgroundColor: "transparent" }} key={notification.id}>{notification.message}</Dropdown.Item>
    ))}
  </Dropdown.Menu> */}
{/* </Dropdown> */}

        </li>
</ul>
            );
    }

    
}

export default Auth_nav;


// CustomToggle component to handle bell icon toggle
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));