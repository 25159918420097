import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import './add-customers.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Addcustomers = () => {


  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    street_address: "",
    town: "",
    state: "",
    ZIP_Code: "",
    phone: "",
    email_address: ""
  });

  const navigate = useNavigate();


  const {first_name, last_name, company_name, street_address,town, state , ZIP_Code , phone , email_address } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://node.mumber.com/api/addcustomer", formData);
      console.log(res.data);
    } catch (err) {
      console.log(err.response.data);
    }
  // navigate('/allcustomers');
  toast.success('Data inserted successfully!');
 setFormData({
  first_name: "",
  last_name: "",
  company_name: "",
  street_address: "",
  town: "",
  state: "",
  ZIP_Code: "",
  phone: "",
  email_address: ""
});
   console.log(formData);
  };




  return (
    <div>
      <div className='content-wrapper '>
        <div className='all-pro'>
        Add-Customer
        </div>
        <ToastContainer />
        <form onSubmit={handleSubmit}>

        <div className='add-pro-box1 mt-5 mb-5'>

          <h2>Basic Information</h2>
          <label className='mt-4'>First Name</label><br></br>
         <input className='add-pro-input' type="text" name="first_name" value={first_name} onChange={handleChange} required />


         <label className="mt-3">Last Name </label><br></br>
          <input className='add-pro-input' type="text" name="last_name" value={last_name} onChange={handleChange} required />

           
          <label className="mt-3">Company Name </label><br></br>
          <input className='add-pro-input' type="text" name="company_name" value={company_name} onChange={handleChange} required />


          <label className="mt-3">Street Address </label><br></br>
          <input className='add-pro-input' type="text" name="street_address" value={street_address} onChange={handleChange} required />


          <label className="mt-3">City </label><br></br>
          <input className='add-pro-input' type="text" name="town" value={town} onChange={handleChange} required />


          <label className="mt-3">State </label><br></br>
          <input className='add-pro-input' type="text" name="state" value={state} onChange={handleChange} required />


          <label className="mt-3">ZIP Code </label><br></br>
          <input className='add-pro-input' type="text" name="ZIP_Code" value={ZIP_Code} onChange={handleChange} required />


          <label className="mt-3">Phone Number </label><br></br>
          <input className='add-pro-input' type="text" name="phone" value={phone} onChange={handleChange} required />


          <label className="mt-3">Email Address </label><br></br>
          <input className='add-pro-input' type="text" name="email_address" value={email_address} onChange={handleChange} required />


         
        </div>


        <div className='pro-last'>
        <button type="submit"  className='pro-but mt-5 mb-5'>Save</button>
             </div>
       </form>
      </div>
    </div>
  )
}

export default Addcustomers
