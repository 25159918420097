import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login-register/login';
import Panel from './panel';
import Forgot from './forgot-password';

import moment from 'moment';
import 'moment-timezone';

// Set the default time zone to UTC
moment.tz.setDefault('UTC');

function App() {
  const [isLoggedIn, setLoggedIn] = useState(
    () => window.localStorage.getItem('isLoggedIn') === 'true'
  );

  const handleLogin = () => {
    // Check the user's role from local storage
    const userRole = window.localStorage.getItem('userRole');

    if (userRole === 'admin') {
      setLoggedIn(true);
      window.localStorage.setItem('isLoggedIn', 'true');
    } else {
      // Display an error message or handle access denial here
      // For example, you can redirect to a different route or show a message
      // This code assumes that you have a "accessDenied" route for handling access denial
      // You can modify this according to your application's requirements
      console.log('Access denied. You must be an admin to log in.');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    window.localStorage.removeItem('isLoggedIn');
  };

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/forgot" element={<Forgot onLogin={handleLogin} />} />
        {isLoggedIn ? (
          <Route path="/*" element={<Panel onLogout={handleLogout} />} />
        ) : (
          <Route path="/*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    </>
  );
}

export default App;
