import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Header from './header';
import Menu from './menu';
import Dashboard from './dashboard';
import Footer from './footer';
import Contact from './components/contact';
import Allproducts from './components/products/all-products';
import Addproducts from './components/products/add-products';
import CsvImport from './components/products/CsvImport';
import Allcustomers from './components/customers/all-customers';
import Upcominginvoices from './components/customers/upcoming-invoices';
import Addcustomers from './components/customers/add-customers';
import Customerreview from './components/customer_reviews/customer-review';
import Addreviews from './components/customer_reviews/add-reviews';
import Orderlist from './components/orders/order-list';
import Orderdetails from './components/orders/order-details';
import Allpartner from './components/partner/all-partner';
import Addorder from './components/orders/add-order';
import Editproducts from './components/products/edit-products';
import Editorder from './components/orders/edit-order';
import Search from './components/products/search';
import Addblog from './components/blogs/addblog';
import Allblogs from './components/blogs/allblogs';
import Editblog from './components/blogs/editblog';
import Categorieslist from './components/categories/categorieslist';
import Addcategories from './components/categories/add-categories';
import Editcategorie from './components/categories/edit-categories';
import Editreview from './components/customer_reviews/edit-review';
import Allfaqs from './components/faq/allfaqs';
import Addfaq from './components/faq/addfaq';
import Editfaq from './components/faq/editfaq';
import Editcustomer from './components/customers/editcustomer';
import Notification from './components/notification/Notification';
import Dash from './dash';
import Profile from './Profile';
import Preloader from './Preloader';
import Vieworder from './components/orders/vieworder';
import Forgot from './forgot-password';
import Gateway_setting from './components/gateway_settings';


const Panel = () => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Simulate data fetching or any asynchronous operations
      // For example, you can fetch data from an API or perform other loading tasks here
      // Replace the setTimeout with your actual data fetching logic
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Once the data is fetched, set isLoading to false
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const loggedin = window.localStorage.getItem("isLoggedIn");
  console.log(loggedin,"login");

  return (
    <>

{isLoading ? (
        <Preloader />
      ) : (
      <div className="wrapper">
        <Header />
        <div className="app-sec-part">
          <Menu />
          <Routes>
            {/* <Route path="/" element={<Dashboard />} /> */}
            <Route path="/" element={<Dash/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/allproducts" element={<Allproducts />} />
            <Route path="/addproducts" element={<Addproducts />} />
            <Route path="/CsvImport" element={<CsvImport />} />
            <Route path="/allcustomers" element={<Allcustomers />} />
            <Route path="/upcominginvoices" element={<Upcominginvoices />} />
            <Route path="/addcustomers" element={<Addcustomers />} />
            <Route path="/updatecustomer/:id" element={<Editcustomer/>} />
            <Route path="/customerreview" element={<Customerreview />} />
            <Route path="/addreviews" element={<Addreviews />} />
            <Route path="/updatereview/:id" element={<Editreview />} />
            <Route path="/orderlist" element={<Orderlist />} />
            <Route path="/orderdetail" element={<Orderdetails />} />
            <Route path="/addorder" element={<Addorder />} />
            <Route path="/allpartner" element={<Allpartner />} />
            <Route path="/update/:id" element={<Editproducts />} />
            <Route path="/updateOrder/:id" element={<Editorder />} />
            <Route path="/vieworder/:id" element={<Vieworder />} />
            <Route path="/search" element={<Search />} />
            <Route path="/allblogs" element={<Allblogs />} />
            <Route path="/addblog" element={<Addblog />} />
            <Route path="/addfaq" element={<Addfaq />} />
            <Route path="/updatefaq/:id" element={<Editfaq />} />
            <Route path="/updateBlog/:id" element={<Editblog />} />
            <Route path="/allfaqs" element={<Allfaqs />} />
            <Route path="/updateBlog/:id" element={<Editblog />} />
            <Route path="/addcategories" element={<Addcategories />} />
            <Route path="/categorieslist" element={<Categorieslist />} />
            <Route path="/updateCategorie/:id" element={<Editcategorie />} />
            <Route path="/Profile/:id" element={<Profile/>} />
            <Route path="/Notification" element={<Notification/>} />
            <Route path="/forgot" element={<Forgot/>} />
            <Route path="/Gateway_setting" element={<Gateway_setting/>} />
          </Routes>
        </div>
        <Footer />
      </div>
        )}
    </>
  );
};

export default Panel;
